"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapToUserFromAttributes = void 0;
const CustomAttributes_1 = require("./CustomAttributes");
const userTypeFromString_1 = require("./userTypeFromString");
const mapToUserFromAttributes = (attributes) => {
    // iterate on the attributes we expect to find
    // todo: reduce instead! (and don't create empty!?)
    const attrEntries = CustomAttributes_1.cognitoAttributeNames
        .filter((attrName) => attrName in attributes)
        .map((attrName) => [snakeCaseToCamelCase(attrName), attributes[attrName]]);
    const customAttrEntries = CustomAttributes_1.cognitoCustomAttributeNames
        .filter((attribute) => !CustomAttributes_1.cognitoCustomAttributeNamesDeprecated.includes(attribute))
        .filter((attrName) => `custom:${attrName}` in attributes)
        .map((attrName) => [attrName, attributes[`custom:${attrName}`]]);
    const allAttrEntries = [...attrEntries, ...customAttrEntries];
    const userAllStringProps = Object.fromEntries(allAttrEntries);
    const user = {
        ...userAllStringProps,
        userType: (0, userTypeFromString_1.userTypeFromString)(userAllStringProps.userType),
    };
    return user;
};
exports.mapToUserFromAttributes = mapToUserFromAttributes;
// https://stackoverflow.com/a/54243315/15909256
const snakeCaseToCamelCase = (input) => input
    .split('_')
    .reduce((res, word, i) => i === 0
    ? word.toLowerCase()
    : `${res}${word.charAt(0).toUpperCase()}${word.substring(1).toLowerCase()}`, '');
