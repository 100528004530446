"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Referrals = exports.ReferralCode = void 0;
var ReferralCode;
(function (ReferralCode) {
    ReferralCode["herts"] = "herts";
    ReferralCode["hertsnwst"] = "hertsnwst";
    ReferralCode["hertsnwd"] = "hertsnwd";
    ReferralCode["hertsnwdtest"] = "hertsnwdtest";
    ReferralCode["midlands"] = "midlands";
    ReferralCode["midlandstest"] = "midlandstest";
    ReferralCode["sussextest"] = "sussextest";
    ReferralCode["hertssww"] = "hertssww";
    ReferralCode["hertsswb"] = "hertsswb";
    ReferralCode["hertsbs"] = "hertsbs";
    ReferralCode["trent"] = "trent";
    ReferralCode["cambsdigital"] = "cambsdigital";
    ReferralCode["demo"] = "demo";
    ReferralCode["prolific"] = "prolific";
    ReferralCode["priory"] = "priory";
    ReferralCode["testquicksilvagp"] = "testquicksilvagp";
    ReferralCode["testmesh"] = "testmesh";
    ReferralCode["sussex"] = "sussex";
})(ReferralCode = exports.ReferralCode || (exports.ReferralCode = {}));
// prettier-ignore
exports.Referrals = {
    herts: { code: ReferralCode.herts, serviceName: 'Herts', serviceCode: 'herts' },
    hertsnwst: {
        code: ReferralCode.hertsnwst,
        serviceName: 'Herts',
        serviceCode: 'herts',
        pathwayCode: 'nwst',
        pathwayName: 'North West St Albans',
    },
    hertsnwd: {
        code: ReferralCode.hertsnwd,
        serviceName: 'Herts',
        serviceCode: 'herts',
        pathwayCode: 'nwd',
        pathwayName: 'North West Dacorum',
    },
    hertsnwdtest: {
        code: ReferralCode.hertsnwdtest,
        serviceName: 'Herts Test',
        serviceCode: 'herts',
        pathwayCode: 'nwdtest',
        pathwayName: 'North West Dacorum',
    },
    hertssww: {
        code: ReferralCode.hertssww,
        serviceName: 'Herts',
        serviceCode: 'herts',
        pathwayCode: 'sww',
        pathwayName: 'South West Watford',
    },
    hertsswb: {
        code: ReferralCode.hertsswb,
        serviceName: 'Herts',
        serviceCode: 'herts',
        pathwayCode: 'swb',
        pathwayName: 'South West Boreham Wood',
    },
    hertsbs: {
        code: ReferralCode.hertsbs,
        serviceName: 'Herts',
        serviceCode: 'herts',
        pathwayCode: 'bs',
        pathwayName: 'ESE Bishop’s Stortford',
    },
    trent: { code: ReferralCode.trent, serviceName: 'Trent', serviceCode: 'trent' },
    cambsdigital: {
        code: ReferralCode.cambsdigital,
        serviceName: 'Cambridge Digital',
        serviceCode: 'cambridgedigital',
    },
    demo: { code: ReferralCode.demo, serviceName: 'Demo', serviceCode: 'demo' },
    prolific: { code: ReferralCode.prolific, serviceName: 'Prolific', serviceCode: 'prolific' },
    priory: { code: ReferralCode.priory, serviceName: 'Priory', serviceCode: 'priory' },
    testquicksilvagp: { code: ReferralCode.testquicksilvagp, serviceName: 'testquicksilvagp', serviceCode: 'testquicksilvagp' },
    midlandstest: { code: ReferralCode.midlandstest, serviceName: 'Midlands Test', serviceCode: 'midlandstest' },
    midlands: { code: ReferralCode.midlands, serviceName: 'Midlands', serviceCode: 'midlands' },
    sussextest: { code: ReferralCode.sussextest, serviceName: 'Sussex Test', serviceCode: 'sussextest' },
    sussex: {
        code: ReferralCode.sussex,
        serviceName: 'Sussex',
        serviceCode: 'sussex',
    },
    testmesh: { code: ReferralCode.testmesh, serviceName: 'testmesh', serviceCode: 'testmesh' },
};
