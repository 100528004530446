import React, { useContext, useEffect, Dispatch } from 'react'
import { captureException } from '@sentry/react'
import { OrganisationContext } from '../context/organisation'
import { Button } from '@psyomics/components'
import { useAuth } from 'registration/context/auth'
import { useNavigate } from 'react-router-dom'
import { FormControlLabel, Switch } from '@mui/material'
import { styled } from '@mui/system'
import Styles from './ReportNav.module.scss'
import TopNavBar from '../components/TopNavBar'
import { InfoContext } from 'configure'
import { demoReports } from 'registration/pages/Demo/ClinicalReports/fakeData'
import { Maybe, AllExistingReports } from 'censeo-core/dist'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    '& .label': {
        color: 'var(--c-teal-light)',
    },
    '&:hover': {
        '& .label': {
            color: 'var(--c-teal-mid)',
        },
        '& .MuiSwitch-switchBase': {
            color: 'var(--c-teal-mid)',
        },
    },
}))

const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        color: 'var(--c-teal-light)',
        '&.Mui-checked': {
            color: 'var(--c-teal-light)',
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'var(--c-teal-light)',
        },
    },
}))

interface Props {
    reportMaxWidth: string
    currentAssessmentId: string
    enableToggleButton?: boolean
    allCompletedReportsForUser?: Maybe<Maybe<AllExistingReports>[]>
    isAuthorisedForPatient?: boolean
    setIsAuthorisedForPatient: Dispatch<React.SetStateAction<boolean | undefined>>
}

export const ReportNav: React.FC<Props> = ({
    reportMaxWidth,
    currentAssessmentId,
    enableToggleButton = false,
    allCompletedReportsForUser,
    isAuthorisedForPatient,
    setIsAuthorisedForPatient,
}) => {
    const navigate = useNavigate()
    const organisation = useContext(OrganisationContext)
    const { signOutUser } = useAuth()
    const info = useContext(InfoContext)

    const [toggleState, setToggleState] = React.useState(false)
    const [orgCode, setOrgCode] = React.useState()

    useEffect(() => {
        const isReportV2 = location.pathname.endsWith('/v2')
        setToggleState(isReportV2)
    }, [location.pathname])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggleState(event.target.checked)
        if (event.target.checked) {
            const reportV2Url = location.pathname + '/v2'
            navigate(reportV2Url)
        } else {
            const reportV1Url = location.pathname.replace(/\/v2$/, '')
            navigate(reportV1Url)
        }
    }

    useEffect(() => {
        ;(async () => {
            const reportId = window.location.pathname.split('/')[2]
            const orgResponse = await fetch(`${info?.referralApiUrl}referrals/unauthenticated?assessmentId=${reportId}`)

            if (orgResponse.status === 404) {
                captureException(`Could not find referral for assessment/report id ${reportId}`)
                return
            }
            if (!orgResponse.ok) {
                captureException(`Failed to fetch referral from api using assessment/report id ${reportId}`)
                return
            }

            const data = await orgResponse.json()
            if (data.organisationCode) {
                setOrgCode(data.organisationCode)
            } else {
                captureException(`Could not find orgCode in following referral: ${JSON.stringify(data, null, 2)}`)
            }
        })()
    }, [location.pathname])

    const pathComponents = location.pathname.split('/')
    const assessmentId = pathComponents[pathComponents.length - 1]
    const isDemoReport = demoReports.ids.includes(assessmentId)

    return (
        <div
            className={Styles.container}
            style={{
                maxWidth: reportMaxWidth,
            }}
        >
            <div className={Styles.leftSection}>
                {organisation?.demo && (
                    <Button
                        appearance="link"
                        size="large"
                        icon="home-button"
                        css={{ ml: 5 }}
                        onClick={async () => {
                            await signOutUser()
                            if (organisation.organisationCode === 'demo-priory') {
                                navigate('/demo/priory/experience')
                            } else {
                                navigate('/demo/experience')
                            }
                        }}
                        aria-label="Homepage"
                    />
                )}
                {(!organisation?.demo || !isDemoReport) && orgCode && (
                    <TopNavBar
                        settings={{ enablePrevButton: false, enableHomepageButton: true }}
                        onHomepageClick={() => (window.location.href = `/account/${orgCode}`)}
                        reportSelect={
                            allCompletedReportsForUser && allCompletedReportsForUser.length > 0
                                ? { currentAssessmentId, allCompletedReportsForUser }
                                : undefined
                        }
                        isPatientFacingReportAuthorised={isAuthorisedForPatient}
                        setIsAuthorisedForPatient={setIsAuthorisedForPatient}
                        currentAssessmentId={currentAssessmentId}
                    />
                )}
            </div>
            {enableToggleButton && !isDemoReport && (
                <div className={Styles.rightSection} style={{ paddingRight: toggleState ? '2px' : '28px' }}>
                    <StyledFormControlLabel
                        control={
                            <StyledSwitch
                                checked={toggleState}
                                onChange={handleChange}
                                name="checked"
                                color="primary"
                            />
                        }
                        label={<span className="label">Old report version</span>}
                        labelPlacement="start"
                    />
                </div>
            )}
        </div>
    )
}
