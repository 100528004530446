import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Auth } from 'aws-amplify'
import { toAbsolutePath } from '../hostOverride'
import * as Sentry from '@sentry/react'

const httpLink = createHttpLink({
    uri: toAbsolutePath('/graphql'),
})

const authLink = setContext((_, { headers }) =>
    Auth.currentSession().then((data) => ({
        headers: {
            ...headers,
            authorization: data.getIdToken().getJwtToken(),
        },
    }))
)

const errorLink = onError(({ networkError }) => {
    if (networkError && 'response' in networkError) {
        const response = (networkError as { response: Response }).response

        const headers = response.headers

        const headersObject: Record<string, string> = {}
        if (headers && headers.forEach) {
            headers.forEach((value, key) => {
                headersObject[key] = value
            })
        } else {
            console.error('Headers not found or not iterable', headers)
        }

        const requestId = headers.get('X-Amzn-Requestid')
        if (!requestId) {
            console.error('Request ID not found in headers', headers)
        }

        Sentry.captureException(new Error('graphql error'), {
            extra: {
                networkError,
                headers: headersObject,
                'X-Amzn-Requestid': requestId,
            },
        })
    } else {
        console.error('Network error or response not found', networkError)
    }
})

const link = ApolloLink.from([authLink, errorLink, httpLink])

export const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
})
