import React, { useEffect, useContext, useState } from 'react'
import { Auth } from 'aws-amplify'
import { defaults } from 'chart.js'
import { Page, Heading } from '@psyomics/components'
import { InfoContext } from 'configure'
import { Pie, Bar } from 'react-chartjs-2'
import { ragScoresNames, EvaluatedAspects, conditionInformation, UserType } from 'censeo-core'
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material'
import Card from 'registration/components/Card'
import LogoSpinner from '../../components/LogoSpinner/LogoSpinner'
import Error from '../Error'
import { useNavigate, useParams } from 'react-router-dom'
import TopNavBar from 'ui/app/components/TopNavBar'
import { useAuth } from 'registration/context/auth'

export const OVERALL_RAG_SELECTOR = 'overall' as const
type RagSelection = RagName | typeof OVERALL_RAG_SELECTOR
export enum RagName {
    Suicide = 'Suicide',
    SelfHarm = 'Self harm',
    Trauma = 'Trauma',
    EmotionalInstability = 'Emotional instability',
}

defaults.font.family = 'TTCommons'

interface Detail {
    name: RagName
    score: number
}

export interface Outcomes {
    ragScore: null | {
        score: number
        details: Detail[]
    }
    clinicalOutcomes: null | EvaluatedAspects
}

type DataSet = {
    label: string
    data: number[]
    backgroundColor: string[]
    borderColor: string[]
    borderWidth: number
}

type PieChartData = {
    labels: string[]
    datasets: DataSet[]
}

interface BarChartData {
    labels: string[]
    datasets: {
        label: string
        data: number[]
        backgroundColor: string
    }[]
}

const PatientOutcomes: React.FC = () => {
    const { getCurrentUser } = useAuth()
    const info = useContext(InfoContext)
    const { organisationCode } = useParams<{ organisationCode: string }>()
    const navigate = useNavigate()

    const [outcomesState, setOutcomesState] = useState<Outcomes[] | undefined>()

    const [barData, setBarData] = useState<BarChartData | undefined>()
    const [pieData, setPieData] = useState<PieChartData | undefined>()
    const [errorMessage, setErrorMessage] = useState<{ title: string; message: string } | undefined>()

    const [ragSelection, setRagSelection] = useState<RagSelection>(OVERALL_RAG_SELECTOR)

    useEffect(() => {
        ;(async () => {
            const res = await Auth.currentSession()
            const jwt = res.getAccessToken().getJwtToken()

            const result = await fetch(
                info?.referralApiUrl +
                    'practitioner/population-health?organisationCode=' +
                    encodeURIComponent(organisationCode as string),
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (result.status === 401) {
                setErrorMessage({
                    title: 'Access Denied',
                    message: 'Please contact support if you believe you should have access to this page.',
                })
                return
            }
            const jsonParsed = await result.json()
            if (
                Array.isArray(jsonParsed) &&
                jsonParsed.length > 0 &&
                'ragScore' in jsonParsed[0] &&
                'clinicalOutcomes' in jsonParsed[0]
            ) {
                setOutcomesState(jsonParsed as Outcomes[])
            } else if (Array.isArray(jsonParsed)) {
                setOutcomesState([])
            }
        })()
    }, [])

    useEffect(() => {
        if (outcomesState) {
            const dataForRagPie = convertRagScoresToChartData(outcomesState, ragSelection)
            setPieData(dataForRagPie)
        }
    }, [outcomesState, ragSelection])

    useEffect(() => {
        ;(async () => {
            if (outcomesState) {
                const user = await getCurrentUser()
                const barData = convertClinicalOutcomesToBarData(
                    outcomesState,
                    user?.attributes['custom:userType'] as UserType
                )
                setBarData(barData)
            }
        })()
    }, [outcomesState])

    const barChartOptions = {
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    }

    if (errorMessage) {
        return (
            <Error
                customMessage={errorMessage.message}
                customTitle={errorMessage.title}
                homepageRedirect={`/account/${organisationCode}`}
            ></Error>
        )
    }

    return (
        <Page width="wide">
            <TopNavBar
                onHomepageClick={() => {
                    navigate(`/account/${organisationCode}`)
                }}
            />
            <Heading el="h1" size="heading1" color="mid" css={{ mt: 5, mb: 5 }}>
                Population Health
            </Heading>

            <Heading el="h2" size="heading2" color="mid" css={{ mb: 4, mt: 4 }}>
                Condition Coverage
            </Heading>
            <div>
                {barData ? (
                    <Card style={{ marginBottom: '30px' }}>
                        <Bar options={barChartOptions} data={barData}></Bar>
                    </Card>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div>
                            <LogoSpinner></LogoSpinner>
                        </div>
                    </div>
                )}
            </div>
            <Heading el="h2" size="heading2" color="mid" css={{ mb: 4, mt: 4 }}>
                Triage Priority
            </Heading>
            {pieData ? (
                <Card>
                    <FormControl style={{ maxWidth: '300px' }}>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={ragSelection}
                            label="Type"
                            onChange={(e) => {
                                setRagSelection(e.target.value as RagName)
                            }}
                        >
                            <MenuItem value={OVERALL_RAG_SELECTOR}>Overall</MenuItem>
                            {Object.values(RagName).map((rag) => {
                                return (
                                    <MenuItem key={rag} value={rag}>
                                        {rag}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <div>
                        <div style={{ maxWidth: '450px' }}>
                            <Pie data={pieData}></Pie>
                        </div>
                    </div>
                </Card>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                        <LogoSpinner></LogoSpinner>
                    </div>
                </div>
            )}
        </Page>
    )
}

export const convertRagScoresToChartData = (outcomes: Outcomes[], selection?: RagSelection): PieChartData => {
    const counts: { [index: number]: number } = {}

    const labels = []
    for (const score in ragScoresNames) {
        labels.push(ragScoresNames[score])
        counts[score] = 0
    }

    outcomes.forEach((outcome) => {
        if (selection !== OVERALL_RAG_SELECTOR) {
            const score = outcome.ragScore?.details.find((individualRag) => individualRag.name === selection)?.score
            if (score != undefined && counts[score] !== undefined) {
                counts[score]++
            }
        } else if (outcome.ragScore && counts[outcome.ragScore.score] !== undefined) {
            counts[outcome.ragScore.score]++
        }
    })

    const data = Object.values(counts)
    return {
        labels: labels,
        datasets: [
            {
                label: 'Service Users',
                data: data,
                backgroundColor: [
                    'rgba(123,125,130, 0.3)',
                    'rgba(247,9,17, 0.3)',
                    'rgba(255,153,0,0.3)',
                    'rgba(249,205,1,0.3)',
                    'rgba(36,155,36, 0.3)',
                ],
                borderColor: [
                    'rgb(123,125,130)',
                    'rgba(247,9,17, 1)',
                    'rgba(255,153,0, 1)',
                    'rgba(249,205,1, 1)',
                    'rgba(36,155,36, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }
}

export function convertClinicalOutcomesToBarData(outcomes: Outcomes[], userType?: UserType): BarChartData {
    const labels: string[] = []

    const conditionsToHide = [
        'Trauma',
        'Self harm',
        'Past Alcohol use and dependency',
        'Past substance abuse',
        'Suicide',
        'Alcohol use and dependency',
    ]

    const datasets = [
        {
            label: 'Highly likely',
            data: [] as number[],
            backgroundColor: 'rgb(227,126,147)',
        },
        {
            label: 'Probable',
            data: [] as number[],
            backgroundColor: 'rgb(255,169,198)',
        },
        {
            label: 'Possible',
            data: [] as number[],
            backgroundColor: 'rgb(255,201,235)',
        },
        {
            label: 'Unlikely',
            data: [] as number[],
            backgroundColor: '#468681',
        },
    ]
    const [severe, clinical, subclinical, low] = datasets

    outcomes.forEach((outcome) => {
        if (outcome?.clinicalOutcomes) {
            Object.keys(outcome.clinicalOutcomes).forEach((aspect) => {
                if (!outcome.clinicalOutcomes) {
                    return
                }
                const value = outcome.clinicalOutcomes[aspect]
                if (typeof value === 'number') {
                    const label = conditionInformation(userType)[aspect]?.name || aspect

                    if (conditionsToHide.some((cond) => cond === label)) return

                    if (!labels.includes(label)) {
                        labels.push(label)
                        datasets.forEach((dataset) => {
                            dataset.data.push(0)
                        })
                    }
                    const index = labels.indexOf(label)

                    switch (true) {
                        case value <= 0.25:
                            low.data[index]++
                            break
                        case value <= 0.5:
                            subclinical.data[index]++
                            break
                        case value <= 0.75:
                            clinical.data[index]++
                            break
                        case value <= 1:
                            severe.data[index]++
                            break
                        default:
                            break
                    }
                }
            })
        }
    })
    const bardata = {
        labels,
        datasets,
    }

    return bardata
}

export default PatientOutcomes
