import { Button, Page, Text, ProgressDots, Heading } from '@psyomics/components'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './Demo.module.scss'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import DemoHeader from 'ui/app/components/DemoHeader'
import { isENGBLocale } from 'registration/utils/locale'

const DemoIntroAssessmentOutcome: React.FC = () => {
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoIntro } = useMixpanelEvent(mixpanelApiUrl)

    useEffect(() => {
        visitedDemoIntro(undefined, undefined)
    }, [])

    return (
        <Page
            style={{ backgroundColor: 'transparent' }}
            layout="standard"
            width="narrow"
            header={<DemoHeader navButtons={false} />}
        >
            <Heading el="h2" size="heading2" color="mid" css={{ mb: 2 }}>
                What is Censeo
            </Heading>
            <Text size="medium" css={{ mb: 5 }}>
                Once the assessment is complete, a report is automatically generated. This includes information on the
                patients/service users&apos; current concerns, social, relational and occupational factors{' '}
                {isENGBLocale() && '(many of which match the NHS Mental Health Minimum Data Set)'} that may be
                contributing towards their mental health, and potential matches to a variety of mental health
                conditions, as well as a risk summary.
            </Text>

            <Text size="medium" css={{ mb: 5 }}>
                The report can be used to save time gathering and analysing information, improving and supporting
                clinical decisions, and informing the next steps of care for the patient/service users.
            </Text>

            <ProgressDots count={5} current={3}></ProgressDots>
            <div className={Styles.btnWrapper}>
                <Button
                    className="text-right"
                    appearance="primary"
                    rounded
                    size="large"
                    label="Continue"
                    onClick={() => {
                        navigate(`/demo/experience`)
                    }}
                />
            </div>
        </Page>
    )
}

export default DemoIntroAssessmentOutcome
