import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { ArcElement, Chart, Legend, Tooltip } from 'chart.js'
import { ChartProps } from './Stats'

Chart.register(ArcElement, Tooltip, Legend)

interface ChartData {
    labels: string[]
    datasets: {
        label: string
        data: number[]
        backgroundColor: string[]
        borderColor: string[]
        borderWidth: number
    }[]
}

const RiskChart: React.FC<ChartProps> = ({ fromDate, toDate, defaultToDate }) => {
    const initialData: ChartData = {
        labels: ['Low', 'Moderate', 'High', 'Very High'],
        datasets: [
            {
                label: '# of Patients',
                data: [211, 145, 90, 22],
                backgroundColor: ['#259B24', '#F9CC01', '#FF9901', '#f70811'],
                borderColor: ['#000000'],
                borderWidth: 1,
            },
        ],
    }

    const [chartData, setChartData] = useState<ChartData>(initialData)

    const filterData = (data: ChartData, fromDate: string, toDate: string): ChartData => {
        const from = new Date(fromDate)
        const to = new Date(toDate)

        const filteredLabels = data.labels
        const filteredDataSets = data.datasets
        if (from.getDate() === new Date(defaultToDate).getDate() && to.getDate() === new Date(Date.now()).getDate()) {
            return initialData
        } else {
            const totalDays = Math.ceil((to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24))
            const initialDays = 365
            const percentage = totalDays / initialDays

            const fakerValueKey = `${totalDays}_days`
            let fakerValue = localStorage.getItem(fakerValueKey) as unknown as number
            if (!fakerValue) {
                const min = 0.1
                const max = 1
                fakerValue = Math.random() * (max - min) + min
                localStorage.setItem(fakerValueKey, fakerValue.toString())
            }

            filteredDataSets[0].data = data.datasets[0].data.map((value) =>
                Math.round(value * percentage * (1 - fakerValue))
            ) as number[]
        }

        const filteredChartData: ChartData = {
            labels: filteredLabels,
            datasets: filteredDataSets,
        }

        return filteredChartData
    }

    useEffect(() => {
        if (fromDate !== initialData.labels[0] || toDate !== defaultToDate) {
            const filteredData = filterData(initialData, fromDate, toDate)
            setChartData(filteredData)
        }
    }, [fromDate, toDate])

    return <Pie data={chartData} />
}

export default RiskChart
