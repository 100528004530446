import { useMutation, useQuery } from '@apollo/client'
import {
    allSessionsFiltered,
    conditionByQuestionId,
    getQuestionsForSession,
    getSessionFromQuestionId,
    MutationAnswerArgs,
    MutationInvalidateAnswerArgs,
    QueryQuestionArgs,
    UserType,
    screenInQuestionIds,
} from 'censeo-core'
import React, { useContext, useEffect, useMemo } from 'react'
import * as Sentry from '@sentry/react'
import { useNavigate, useNavigationType } from 'react-router'
import { answerMutation, AnswerResponse } from '../../../data/mutations/answer'
import { InvalidateAnswerResponse, invalidateAnswerMutation } from '../../../data/mutations/invalidateAnswer'
import { QuestionData, questionQuery } from '../../../data/queries/question'
import { MultipleChoice } from '../components/questions/MultipleChoice'
import { FreeQuestion } from '../components/questions/FreeQuestionToMultipleChoice'
import { FreeTextQuestion } from '../components/questions/FreeTextQuestion'
import { Splash } from './Splash'
import { useAuth } from 'registration/context/auth'
import { useMixpanelEvent } from '../useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { enableTimer, getTimer, restartTimers, Timers } from 'ui/utils/timers'
import { OrganisationContext } from '../context/organisation'
import { ReferralDetailsContext } from '../context/referralDetails'
import CenseoError from './Error'

interface Props {
    questionId: string
    setQuestionId: (newId: string) => void
    restart?: true
    setReferralPublicId?: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const Questionnaire: React.FC<Props> = ({ questionId, setQuestionId, restart, setReferralPublicId }) => {
    const prevQuestionIdRef = React.useRef('')
    const organisation = useContext(OrganisationContext)
    const referralDetails = useContext(ReferralDetailsContext)

    const {
        data,
        loading,
        error: apiError,
    } = useQuery<QuestionData, QueryQuestionArgs>(questionQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            id: questionId,
        },
    })

    const navigate = useNavigate()
    const navigationType = useNavigationType()
    const {
        state: { user },
    } = useAuth()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const {
        assessmentStarted,
        sessionStarted,
        questionAsked,
        questionAnswered,
        sessionCompleted,
        screenedIn,
        assessmentCompleted,
        answerRemoved,
    } = useMixpanelEvent(mixpanelApiUrl)

    const userReferralCode = user?.referralCode
    const userType = user?.userType ?? UserType.Nhs
    const allSessions = useMemo(
        () => allSessionsFiltered(userType, organisation?.assessmentSettings),
        [userType, organisation?.assessmentSettings]
    )
    const session = getSessionFromQuestionId(questionId, organisation?.assessmentSettings)
    const questions = useMemo(
        () => getQuestionsForSession(session, userType, organisation?.assessmentSettings),
        [session, userType, organisation?.assessmentSettings]
    )

    const isFirstSession = allSessions[0].name === session
    const isFirstQuestion = questions?.[0].id === questionId
    const isLastSession = allSessions[allSessions.length - 1].name === session
    const startNewAssessment = restart && navigationType !== 'POP'

    const [mutate, { loading: mutateLoading }] = useMutation<AnswerResponse, MutationAnswerArgs>(answerMutation, {
        onCompleted: ({ answer }) => {
            if (isFirstQuestion) {
                if (isFirstSession) {
                    assessmentStarted(
                        organisation?.organisationCode,
                        organisation?.organisationName || userReferralCode,
                        referralDetails?.data?.publicId
                    )
                    enableTimer(Timers.QuestionTimeSpent)
                }
                sessionStarted(
                    organisation?.organisationCode,
                    organisation?.organisationName || userReferralCode,
                    session,
                    referralDetails?.data?.publicId
                )
            }
            const questionType = questions?.[0].__typename
            const timeSpentOnQuestion = getTimer(Timers.QuestionTimeSpent, true)
            const conditionName = conditionByQuestionId(questionId)
            questionAnswered(
                organisation?.organisationCode,
                organisation?.organisationName || userReferralCode,
                questionId,
                questionType,
                conditionName,
                timeSpentOnQuestion,
                referralDetails?.data?.publicId
            )

            if (answer.__typename === 'InvalidReferral') {
                if (setReferralPublicId && answer.status !== referralDetails?.data?.status) {
                    setReferralPublicId(undefined)
                }
                navigate(`/`)
            }

            if (answer.__typename === 'NextQuestion') {
                setQuestionId(answer.id)
            }

            if (answer.__typename === 'SessionComplete') {
                sessionCompleted(
                    organisation?.organisationCode,
                    organisation?.organisationName || userReferralCode,
                    session,
                    referralDetails?.data?.publicId
                )
                if (isLastSession) {
                    assessmentCompleted(
                        organisation?.organisationCode,
                        organisation?.organisationName || userReferralCode,
                        referralDetails?.data?.publicId
                    )
                }
                setQuestionId('')
                navigate(`/`)
            }
        },
    })

    // todo: loading
    const [goBack, { loading: backLoading }] = useMutation<InvalidateAnswerResponse, MutationInvalidateAnswerArgs>(
        invalidateAnswerMutation,
        {
            onCompleted: ({ invalidateAnswer }) => {
                answerRemoved(
                    organisation?.organisationCode,
                    organisation?.organisationName || userReferralCode,
                    questionId,
                    referralDetails?.data?.publicId
                )
                if (invalidateAnswer.__typename === 'PreviousQuestion') {
                    setQuestionId(invalidateAnswer.id)
                }
                if (invalidateAnswer.__typename === 'SessionStart') {
                    setQuestionId('')
                    navigate(`/`)
                }
            },
        }
    )

    const backHandler = () => {
        goBack({
            variables: {
                questionId,
            },
        })
    }

    useEffect(() => {
        enableTimer(Timers.AssessmentTotalDuration)
        enableTimer(Timers.QuestionTimeSpent)
        restartTimers()
    })

    useEffect(() => {
        if (questionId !== prevQuestionIdRef.current) {
            questionAsked(
                organisation?.organisationCode,
                organisation?.organisationName || userReferralCode,
                questionId,
                referralDetails?.data?.publicId
            )
        }
        prevQuestionIdRef.current = questionId

        if (screenInQuestionIds.includes(questionId)) {
            const conditionName = conditionByQuestionId(questionId)
            screenedIn(
                organisation?.organisationCode,
                organisation?.organisationName || userReferralCode,
                questionId,
                conditionName,
                referralDetails?.data?.publicId
            )
        }
    }, [questionId])

    if (apiError) {
        const errMsg = `GraphQL questionQuery request failed for referral ${referralDetails?.data?.publicId} and question id ${questionId}. Response from API: ${apiError?.message}`
        Sentry.captureException(errMsg)
        return <CenseoError customTitle={`Next question not found (Referral ID: ${referralDetails?.data?.publicId})`} />
    }

    return loading || backLoading ? (
        <Splash />
    ) : (
        <>
            {!loading && data?.question && data.question.__typename === 'RestrictedChoiceQuestion' && (
                <MultipleChoice
                    isMultiselect={data.question.isMultiselect ?? false}
                    question={data.question.text}
                    options={data.question.answers}
                    selectedOptionIds={!startNewAssessment ? data.question.chosenOptionIds || [] : []}
                    onSelect={(ids) => {
                        mutate({
                            variables: {
                                answer: {
                                    questionId: data.question.id,
                                    chosenOptionIds: ids,
                                    startNewAssessment,
                                },
                            },
                        })
                    }}
                    onBack={backHandler}
                    updating={mutateLoading}
                />
            )}
            {!loading && data?.question && data.question.__typename === 'FreeQuestion' && (
                <FreeQuestion
                    question={data.question.text}
                    unit={data.question.unit}
                    answer={startNewAssessment ? undefined : data.question.answer}
                    onSelect={(answer) =>
                        mutate({
                            variables: {
                                answer: {
                                    questionId: data.question.id,
                                    freeValue: answer,
                                    startNewAssessment,
                                },
                            },
                        })
                    }
                    onBack={backHandler}
                    updating={mutateLoading}
                />
            )}
            {!loading && data?.question?.__typename === 'FreeTextQuestion' && (
                <FreeTextQuestion
                    question={data.question}
                    updating={mutateLoading}
                    onSelect={(answerText) =>
                        mutate({
                            variables: {
                                answer: {
                                    questionId: data.question.id,
                                    freeText: answerText,
                                    startNewAssessment,
                                },
                            },
                        })
                    }
                    onBack={backHandler}
                />
            )}
        </>
    )
}
