import React from 'react'

interface CheckCircleIconProps {
    label?: string
}

const CheckCircleIcon: React.FC<CheckCircleIconProps> = ({ label }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                color="var(--c-green-300)"
            >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 7.59L10.59 16 7 12.41l1.41-1.41L10.59 14l5.59-5.59L17 9.59z" />
            </svg>
            <label style={{ color: 'var(--c-teal-mid)' }}>{label}</label>
        </div>
    )
}

export default CheckCircleIcon
