export const enum AssessmentStatus {
    UNCOMPLETED = 'incomplete',
    IN_PROGRESS = 'in progress',
}

export const demoReports = {
    ids: [
        'f530cfdf-321e-405e-b25e-376dae911925',
        '92968fef-98f8-49fc-9f97-e9266e661b60',
        'b6d795e7-b1bc-43fd-a6f0-d11c679bfe06',
        '9270da96-135c-4378-9a9c-15aafc3adc97',
        'c710ae26-04d9-4298-868f-62a31bee005d',
        '7c6b31cc-9051-40cb-b5f5-174a0060e6ad',
    ],
    location: (id: string): string => `https://demo-reports-psyomics.s3.eu-west-2.amazonaws.com/${id}.json`,
    pdfLocation: (id: string): string => `https://demo-reports-psyomics.s3.eu-west-2.amazonaws.com/${id}.full.pdf`,
}

export const enum Risk {
    LOW = 'Low',
    MEDIUM = 'Moderate',
    HIGH = 'High',
    VERY_HIGH = 'Very High',
}
export interface FakeDataRow {
    firstName: string
    givenName: string
    dob: string
    nhsNumber: string
    completed: string | AssessmentStatus
    risk: Risk | undefined
    reportId: string | undefined
}

export const fakeData: FakeDataRow[] = [
    {
        firstName: 'Philipa',
        givenName: 'Coleman',
        dob: '1997-11-12',
        nhsNumber: '994869668',
        completed: '2023-07-21',
        risk: Risk.LOW,
        reportId: demoReports.ids[0],
    },
    {
        firstName: 'Olivia',
        givenName: 'Hall',
        dob: '1992-04-22',
        nhsNumber: '387482871',
        completed: '2023-08-14',
        risk: Risk.MEDIUM,
        reportId: demoReports.ids[1],
    },
    {
        firstName: 'Gary',
        givenName: 'White',
        dob: '1963-11-12',
        nhsNumber: '203082788',
        completed: '2023-07-09',
        risk: Risk.LOW,
        reportId: demoReports.ids[2],
    },
    {
        firstName: 'Aaron',
        givenName: 'Kite',
        dob: '1981-03-25',
        nhsNumber: '527023000',
        completed: '2023-06-15',
        risk: Risk.LOW,
        reportId: demoReports.ids[3],
    },
    {
        firstName: 'Jeremy',
        givenName: 'Parsons',
        dob: '1969-01-29',
        nhsNumber: '630987772',
        completed: '2023-09-01',
        risk: Risk.VERY_HIGH,
        reportId: demoReports.ids[4],
    },
    {
        firstName: 'Zachary',
        givenName: 'Thorburn',
        dob: '1984-11-11',
        nhsNumber: '515112822',
        completed: '2023-09-02',
        risk: Risk.HIGH,
        reportId: demoReports.ids[5],
    },
    {
        firstName: 'James',
        givenName: 'Davis',
        dob: '1979-01-17',
        nhsNumber: '953356419',
        completed: AssessmentStatus.IN_PROGRESS,
        risk: undefined,
        reportId: undefined,
    },
    {
        firstName: 'Maisy',
        givenName: 'Oldburn',
        dob: '1955-06-02',
        nhsNumber: '253820371',
        completed: AssessmentStatus.IN_PROGRESS,
        risk: undefined,
        reportId: undefined,
    },
    {
        firstName: 'Sandra',
        givenName: 'Ruckman',
        dob: '1984-09-31',
        nhsNumber: '704733362',
        completed: AssessmentStatus.UNCOMPLETED,
        risk: undefined,
        reportId: undefined,
    },
]
