import { Button, ProgressDots, Page, Text } from '@psyomics/components'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './Demo.module.scss'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import DemoHeader from 'ui/app/components/DemoHeader'

const ExperienceChoice: React.FC = () => {
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoExperience } = useMixpanelEvent(mixpanelApiUrl)

    useEffect(() => {
        visitedDemoExperience(undefined, undefined)
    }, [])

    return (
        <Page
            style={{ backgroundColor: 'transparent' }}
            layout="standard"
            width="narrow"
            header={<DemoHeader navButtons={false} />}
        >
            <Text size="medium" css={{ mb: 5 }}>
                Would you like to experience Censeo as a patient/service user or as a clinician/manager?
            </Text>
            <Text size="medium" css={{ mb: 5 }}>
                You can come back to this page later on by clicking on the home button in the top left.
            </Text>
            <ProgressDots count={5} current={4}></ProgressDots>
            <div className={Styles.btnWrapper}>
                <Button
                    className="text-right"
                    appearance="primary"
                    rounded
                    size="large"
                    fullWidth={true}
                    label="Patient / Service User"
                    onClick={() => {
                        navigate(`/demo/patient`)
                    }}
                />
            </div>

            <div className={Styles.btnWrapper}>
                <Button
                    className="text-right"
                    appearance="primary"
                    rounded
                    size="large"
                    fullWidth={true}
                    label="Clinician / Manager"
                    onClick={() => {
                        navigate(`/demo/healthcare`)
                    }}
                />
            </div>
        </Page>
    )
}

export default ExperienceChoice
