"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sessionConfigsWithQuestions = exports.d2cSessionConfig = exports.defaultSessionConfigs = exports.sessionConfigContainsAspect = exports.sessionsToAspects = exports.questions = void 0;
const Dem_1 = require("./yaml/generated/Dem");
const AD_1 = require("./yaml/generated/AD");
const BP_1 = require("./yaml/generated/BP");
const MDD_1 = require("./yaml/generated/MDD");
const GAD_1 = require("./yaml/generated/GAD");
const SoA_1 = require("./yaml/generated/SoA");
const Insomnia_1 = require("./yaml/generated/Insomnia");
const PD_1 = require("./yaml/generated/PD");
const EUPD_1 = require("./yaml/generated/EUPD");
const Suicide_1 = require("./yaml/generated/Suicide");
const ED_1 = require("./yaml/generated/ED");
const OCD_1 = require("./yaml/generated/OCD");
const Alcohol_1 = require("./yaml/generated/Alcohol");
const Substance_1 = require("./yaml/generated/Substance");
const Psychosis_1 = require("./yaml/generated/Psychosis");
const PTSD_1 = require("./yaml/generated/PTSD");
const PC_1 = require("./yaml/generated/PC");
exports.questions = {
    DEMOGRAPHICS: Dem_1.questions,
    AD: AD_1.questions,
    MDD: MDD_1.questions,
    GAD: GAD_1.questions,
    SOA: SoA_1.questions,
    PD: PD_1.questions,
    OCD: OCD_1.questions,
    INS: Insomnia_1.questions,
    BP: BP_1.questions,
    PSYCHOSIS: Psychosis_1.questions,
    EUPD: EUPD_1.questions,
    ED: ED_1.questions,
    ALCOHOL: Alcohol_1.questions,
    SUBSTANCE: Substance_1.questions,
    PTSD: PTSD_1.questions,
    PC: PC_1.questions,
    SUICIDE_SELFHARM: Suicide_1.questions,
};
exports.sessionsToAspects = {
    DEMOGRAPHICS: [],
    AD: ['adj_ce'],
    MDD: ['simple_mdd'],
    GAD: ['gad_ce'],
    SOA: ['soa_ce'],
    PD: ['pad_ce'],
    OCD: ['ocd_ce'],
    INS: ['ins_ce'],
    BP: ['simple_bd1', 'simple_bd2'],
    PSYCHOSIS: ['pso_ce'],
    EUPD: ['emi_ce'],
    ED: ['dse_ce'],
    ALCOHOL: ['alc_ce', 'alc_ce_dep', 'alc_pe_dep'],
    SUBSTANCE: ['sbs_ce', 'sbs_pe'],
    PTSD: ['trm_re_ptsd'],
    PC: ['pc'],
    SUICIDE_SELFHARM: ['s_current', 'sh_current'],
};
const sessionConfigContainsAspect = (sessionConfig, aspect) => {
    if (!sessionConfig || sessionConfig.length === 0)
        throw new Error('no session config provided');
    if (!aspect)
        throw new Error('aspect string not provided');
    const aspectIsAccountedFor = Object.values(exports.sessionsToAspects)
        .reduce((acc, aspect) => [...acc, ...aspect], [])
        .includes(aspect);
    if (!aspectIsAccountedFor)
        throw new Error(`aspect: ${aspect}, not in sessionsToAspects`);
    let containsAspect = false;
    sessionConfig.forEach((session) => {
        session.sections.forEach((section) => {
            if (exports.sessionsToAspects[section].includes(aspect)) {
                containsAspect = true;
            }
        });
    });
    return containsAspect;
};
exports.sessionConfigContainsAspect = sessionConfigContainsAspect;
exports.defaultSessionConfigs = [
    {
        name: 'Session 1',
        sections: ['PC', 'AD'],
    },
    {
        name: 'Session 2',
        sections: ['MDD'],
    },
    {
        name: 'Session 3',
        sections: ['PSYCHOSIS', 'EUPD', 'ED', 'ALCOHOL', 'SUBSTANCE'],
    },
    {
        name: 'Session 4',
        sections: ['BP'],
    },
    {
        name: 'Session 5',
        sections: ['GAD', 'SOA', 'PD', 'OCD', 'INS'],
    },
    {
        name: 'Session 6',
        sections: ['PTSD', 'DEMOGRAPHICS', 'SUICIDE_SELFHARM'],
    },
];
exports.d2cSessionConfig = [
    {
        name: 'Session 1',
        sections: ['AD', 'INS'],
    },
    {
        name: 'Session 2',
        sections: ['MDD'],
    },
    {
        name: 'Session 3',
        sections: ['GAD', 'SOA', 'PD', 'OCD'],
    },
    {
        name: 'Session 4',
        sections: ['BP'],
    },
    {
        name: 'Session 5',
        sections: ['PSYCHOSIS', 'EUPD', 'ED', 'ALCOHOL', 'SUBSTANCE'],
    },
    {
        name: 'Session 6',
        sections: ['PC'],
    },
];
const sessionConfigsWithQuestions = (configs) => {
    configs = configs !== null && configs !== void 0 ? configs : exports.defaultSessionConfigs;
    return configs.map((session) => {
        return {
            name: session.name,
            questions: session.sections.reduce((acc, next) => {
                acc = [...acc, ...exports.questions[next]];
                return acc;
            }, []),
        };
    });
};
exports.sessionConfigsWithQuestions = sessionConfigsWithQuestions;
//// A skimmed version for dev/testing purposes. Just uncomment this one and comment the other one above.
// export const sessionConfigs: SessionConfig[] = [
//     {
//         name: "Session 1",
//         questions: [
//             SuicideSelfharmQuestions[0],
//             ADQuestions[0]
//         ]
//     },
//     {
//         name: "Session 2",
//         questions: [
//             SuicideSelfharmQuestions[1],
//             ADQuestions[1]
//         ]
//     },
//     {
//         name: "Session 3",
//         questions: [
//             ADQuestions[2],
//             SuicideSelfharmQuestions[2],
//             ADQuestions[3],
//         ]
//     },
//     {
//         name: "Session 4",
//         questions: [
//             ADQuestions[4],
//             SuicideSelfharmQuestions[3],
//         ]
//     },
//     // should be completely hidden from D2C users!
//     {
//         name: "Session 5",
//         questions: [
//             SuicideSelfharmQuestions[4],
//         ]
//     },
//    ];
//// The original sessions data set used in the pilot experiment. This is used in the integration tests
