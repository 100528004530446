import { gql } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { Next } from 'censeo-core'

export const answerMutation: DocumentNode = gql`
    mutation Answer($answer: AnsweredQuestion!) {
        answer(answer: $answer) {
            ... on NextQuestion {
                id
                __typename
            }
            ... on InvalidReferral {
                status
                __typename
            }
        }
    }
`

export interface AnswerResponse {
    answer: Next
}
