import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Logo, Page, Button, Heading } from '@psyomics/components'
import HelpModal from 'ui/app/components/HelpModal'
import { useNavigate } from 'react-router'
import Styles from './ContentPage.module.scss'

interface HeadingButtonProps {
    display?: boolean
    text: string
    link: () => void
}
interface OurProps {
    /** what will the heading text be */
    headingText?: string
    /** properties for the heading back link  */
    headingButton?: HeadingButtonProps
    /** should we show the crisis menu on this page? */
    showCrisisLink?: boolean
    /** should we show the account menu button? */
    showAccountLink?: boolean
}

type IContentPageProps = OurProps & React.ComponentPropsWithoutRef<'div'>

const ContentPage: React.FC<IContentPageProps> = ({
    headingText,
    headingButton,
    showCrisisLink = true,
    showAccountLink = true,
    children,
    ...props
}) => {
    const CenseoLogo = <Logo format="composite" height="32" />
    const navigate = useNavigate()
    const onAccountClick = () => {
        navigate('/userprofile')
    }
    const Header = (
        <header className={Styles.header}>
            <div className={Styles.helpLink}>{showCrisisLink && <HelpModal />}</div>
            <RouterLink to={'/welcome'} title="Go back to the homepage" className={Styles.homeLink}>
                {CenseoLogo}
            </RouterLink>
            <div className={Styles.accountLink}>
                {showAccountLink && (
                    <Button
                        appearance="link"
                        size="large"
                        aria-label="Show user account menu"
                        onClick={onAccountClick}
                        icon="user"
                    />
                )}
            </div>
        </header>
    )
    return (
        <Page header={Header} {...props}>
            {headingButton && (
                <Button
                    appearance="link"
                    size="small"
                    icon="arrow-left"
                    onClick={headingButton.link}
                    label={headingButton.text}
                ></Button>
            )}
            {headingText && (
                <Heading
                    color="mid"
                    css={{
                        mt: 3,
                        mb: 5,
                    }}
                    size="heading1"
                    el="h1"
                >
                    {headingText}
                </Heading>
            )}
            {children}
        </Page>
    )
}

export default ContentPage
