import React from 'react'
import Styles from './FilterIcon.module.scss'

const FilterIcon: React.FC = () => {
    return (
        <div className={Styles.filterIcon}>
            <svg height="18px" xmlns="http://www.w3.org/2000/svg" version="1.1" width="18" viewBox="0 0 256 256">
                <defs></defs>
                <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                    <path
                        d="M 38.047 90 c -0.507 0 -1.015 -0.128 -1.472 -0.386 c -0.944 -0.532 -1.528 -1.531 -1.528 -2.614 V 45.698 L 4.044 4.813 C 3.356 3.905 3.242 2.686 3.748 1.666 C 4.255 0.645 5.296 0 6.435 0 h 77.129 c 1.14 0 2.18 0.645 2.687 1.666 c 0.507 1.02 0.393 2.239 -0.296 3.147 L 54.952 45.698 v 32.873 c 0 1.049 -0.548 2.021 -1.445 2.565 l -13.904 8.429 C 39.125 89.854 38.586 90 38.047 90 z M 12.475 6 l 27.963 36.877 c 0.396 0.521 0.609 1.158 0.609 1.813 v 36.984 l 7.905 -4.792 V 44.689 c 0 -0.654 0.214 -1.291 0.609 -1.813 L 77.524 6 H 12.475 z M 51.952 78.571 h 0.01 H 51.952 z"
                        transform=" matrix(1 0 0 1 0 0) "
                    />
                </g>
            </svg>
        </div>
    )
}

export default FilterIcon
