import { Button, Heading, Page, Text, ProgressDots } from '@psyomics/components'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './Demo.module.scss'
import Hotjar from '@hotjar/browser'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import DemoHeader from 'ui/app/components/DemoHeader'

const DemoHome: React.FC = () => {
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoHomepage } = useMixpanelEvent(mixpanelApiUrl)

    useEffect(() => {
        const hotjarSiteId = import.meta.env.VITE_APP_HOTJAR_DEMO_ID
        const hotjarVersion = 6
        if (hotjarSiteId) {
            Hotjar.init(Number(hotjarSiteId), hotjarVersion)
        }

        visitedDemoHomepage('demo', undefined)
    }, [])

    return (
        <Page
            style={{ backgroundColor: 'transparent' }}
            layout="standard"
            width="narrow"
            header={<DemoHeader navButtons={false} />}
        >
            <Heading el="h2" size="heading2" color="mid" css={{ mb: 2 }}>
                Welcome to the Censeo demo
            </Heading>
            <Text size="medium" css={{ mb: 5 }}>
                We have designed this site to:
                <br />
                <br />
                1. Give clinicians and managers a taster of Censeo as if you were a patient/service user. <br />
                <br />
                2. Give you the opportunity to evaluate clinical reports based on the full version of Censeo. <br />
                <br />
                3. Provide you with the opportunity to gain insights into the data that is gathered by Censeo
            </Text>

            <Text size="medium" css={{ mb: 5 }}>
                However, please note, that because this is a demo, we only assess a limited number of clinical
                conditions. Therefore, the questions we ask are deliberately reduced, and the clinical report produced
                does not show all potential conditions.
            </Text>
            <ProgressDots count={5} current={1}></ProgressDots>
            <div className={Styles.btnWrapper}>
                <Button
                    className="text-right"
                    appearance="primary"
                    rounded
                    size="large"
                    label="Continue"
                    onClick={() => {
                        navigate(`/demo/intro-overview`)
                    }}
                />
            </div>
        </Page>
    )
}

export default DemoHome
