import React from 'react'
import { Text, Button, Page, Heading } from '@psyomics/components'
import { useNavigate } from 'react-router-dom'
import Header from 'registration/components/Header'

const RenewedReferralWelcome: React.FC = () => {
    const navigate = useNavigate()
    return (
        <Page>
            <Header template="landing" />
            <Heading el="h1" size="heading2" color="mid" css={{ mb: 3 }}>
                Welcome back
            </Heading>
            <Text size="medium" css={{ mb: 5 }}>
                This assessment is designed to give your healthcare professional the information they need to make the
                best decisions about your care. Upon completion of your question sessions, a healthcare professional
                will review the report.
            </Text>
            <Text size="medium" css={{ mb: 5 }}>
                If you have completed this assessment within the last 6 months, and there have been no significant
                changes in your situation, there&#39;s no need to repeat it. Your clinical team retains access to your
                previous information. However, if it has been more than 6 months or if you believe there are updates to
                share, please feel free to complete the assessment again.
            </Text>
            <Button
                type="button"
                appearance="primary"
                rounded
                fullWidth
                size="large"
                css={{ mt: 6 }}
                label="Next"
                onClick={() => navigate('/signin')}
            />
        </Page>
    )
}

export default RenewedReferralWelcome
