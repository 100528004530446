import React from 'react'
import { Heading, SessionCard, SessionTimeline } from '@psyomics/components'
import ContentPage from 'ui/app/components/ContentPage'
import Styles from './CheckIn.module.scss'

export const SessionSummary: React.FC<ISessionSummary> = ({ isFirstVisit, completeSessionCards, allSessionCards }) => {
    return (
        <ContentPage>
            <Heading color="mid" className={Styles.welcomeTitle} size="heading1" el="h1">
                Welcome {isFirstVisit ? '' : 'Back'}
            </Heading>
            <SessionTimeline currentSession={completeSessionCards.length + 1} className={Styles.timeline}>
                {allSessionCards.map((session) => (
                    <SessionCard key={session.sessionNumber} {...session} />
                ))}
            </SessionTimeline>
        </ContentPage>
    )
}
interface ISessionSummary {
    isFirstVisit: boolean
    completeSessionCards: any[]
    allSessionCards: any[]
}
