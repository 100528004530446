import { captureException } from '@sentry/react'
import { InfoContext } from 'configure'
import { useAuth } from '../../../registration/context/auth'
import React, { useState, useEffect, useContext, createContext } from 'react'

export const ReferralDetailsContext = createContext<ReferralDetails | null>(null)

export type ReferralDetails = {
    status: 'no-id' | 'error' | 'not-found' | 'success' | 'loading' | 'pending'
    data?: {
        organisationCode: string
        organisationName: string
        validLink: boolean
        hasUserId: boolean
        publicId: string
        organisationNameAbbr?: string
        status: string
    }
    activeReferral?: {
        organisationCode: string
        organisationName: string
        validLink: boolean
        hasUserId: boolean
        publicId: string
        organisationNameAbbr?: string
        status: string
    }
}

interface ReferralDetailsProps {
    children: React.ReactNode
    referralPublicId?: string
    setReferralPublicId?: React.Dispatch<React.SetStateAction<string | undefined>>
    callback?: (referralDetails: ReferralDetails['data']) => void
}

export function ReferralDetailsContextProvider({
    children,
    referralPublicId,
    setReferralPublicId,
    callback,
}: ReferralDetailsProps): JSX.Element {
    const info = useContext(InfoContext)
    const [referralDetails, setReferralDetails] = useState<ReferralDetails>({ status: 'pending' })
    const { getCurrentUser, state } = useAuth()

    useEffect(() => {
        ;(async () => {
            if (!referralPublicId) {
                const currentUser = await getCurrentUser()
                if (currentUser?.attributes?.['custom:referralPublicId']) {
                    referralPublicId = currentUser?.attributes?.['custom:referralPublicId']
                }
            }
            if (referralPublicId) {
                setReferralDetails({ status: 'loading' })

                try {
                    const orgUrl = `${info?.referralApiUrl}referrals/unauthenticated?publicId=${referralPublicId}`
                    const openedLinkUrl = `${orgUrl}&action=openedLink`
                    const activeReferralUrl = `${info?.referralApiUrl}referrals/unauthenticated?activeReferral=${referralPublicId}`
                    const [orgResponse, openedLinkResponse, activeReferralResponse] = await Promise.all([
                        retryFetch(orgUrl), // Get org
                        retryFetch(openedLinkUrl, { method: 'POST' }), // Open link
                        retryFetch(activeReferralUrl), // Get active referral details
                    ])

                    if (!orgResponse || !openedLinkResponse) return

                    if (orgResponse.status === 404) {
                        setReferralDetails({ status: 'not-found' })
                        captureException('404 not found - for publicId: ' + referralPublicId)
                        return
                    }
                    if (!orgResponse.ok) {
                        throw new Error(`HTTP error! Status: ${orgResponse.status} - ${orgResponse.statusText}`)
                    }
                    if (!openedLinkResponse.ok) {
                        captureException(
                            `Error in openedLink response from referrals API status:${openedLinkResponse.status}`,
                            { extra: { url: openedLinkUrl, response: openedLinkResponse.text } }
                        )
                    }
                    if (!activeReferralResponse.ok) {
                        captureException(
                            `Error in activeReferral response from referrals API status:${activeReferralResponse.status}`,
                            { extra: { url: activeReferralUrl, response: activeReferralResponse.text } }
                        )
                    }

                    const data = (await orgResponse.json()) as ReferralDetails['data']
                    if (callback) {
                        callback(data)
                    }
                    const activeReferral = (await activeReferralResponse.json()) as ReferralDetails['activeReferral']
                    setReferralDetails({ status: 'success', data, activeReferral })
                } catch (error) {
                    if (error instanceof Error && error.name !== 'AbortError') {
                        captureException(error.message)
                    } else {
                        captureException(String(error))
                    }
                    setReferralDetails({ status: 'error' })
                }
            } else if (referralDetails.status === 'pending') {
                setReferralDetails({ status: 'no-id' })
            }
        })()
    }, [referralPublicId, setReferralPublicId, info?.referralApiUrl, state, getCurrentUser, callback])
    return <ReferralDetailsContext.Provider value={referralDetails}>{children}</ReferralDetailsContext.Provider>
}

const retryFetch = async (url: string, options?: RequestInit, retries = 3, delay = 1000): Promise<Response> => {
    try {
        const response = await fetch(url, { ...options })
        return response
    } catch (error) {
        if (error instanceof Error && error.name === 'AbortError' && retries > 0) {
            await new Promise((resolve) => setTimeout(resolve, delay))
            return retryFetch(url, options, retries - 1, delay)
        } else {
            throw error
        }
    }
}
