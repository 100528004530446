import React, { useEffect, useState } from 'react'
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { ChartProps } from './Stats'

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface ChartData {
    labels: string[]
    datasets: {
        label: string
        data: number[]
        backgroundColor: string
    }[]
}

const ConditionChart: React.FC<ChartProps> = ({ fromDate, toDate, defaultToDate }) => {
    const options = {
        indexAxis: 'x' as const,
        responsive: true,
        scaleShowValues: true,
    }

    const labels = [
        'Bipolar I', // 188, 92, 30
        'Bipolar II', // 206, 28, 5
        'Psychosis', // 164, 69, 24
        'EUPD', // 296, 189, 40
        'Trauma', // 380, 85, 56
        'Eating disorders', // 79, 68, 34
        'MDD', // 347, 173, 50
        'GAD', // 356, 229, 65
        'Social anxiety disorder', // 340, 140, 50
        'Panic disorder', // 279, 154, 46
        'OCD', // 185, 114, 39
        'Adjustment disorder', // 64, 33, 6
        'Insomnia', // 296, 189, 40
        'Alcohol use', // 0, 41, 12
        'Substance use', // 0, 66, 21
    ]

    const initialData: ChartData = {
        labels,
        datasets: [
            {
                label: 'Sub-Clinical (# of patients)',
                data: [188, 206, 164, 296, 380, 79, 347, 356, 340, 279, 185, 64, 296, 0, 0],
                backgroundColor: '#FFCE03',
            },
            {
                label: 'Clinical (# of patients)',
                data: [92, 28, 69, 189, 85, 68, 173, 229, 140, 154, 114, 33, 189, 41, 66],
                backgroundColor: '#FD6104',
            },
            {
                label: 'Severe (# of patients)',
                data: [30, 5, 24, 40, 56, 34, 50, 65, 50, 46, 39, 6, 40, 12, 21],
                backgroundColor: '#F00505',
            },
        ],
    }

    const [chartData, setChartData] = useState<ChartData>(initialData)

    const filterData = (data: ChartData, fromDate: string, toDate: string): ChartData => {
        const from = new Date(fromDate)
        const to = new Date(toDate)

        const filteredLabels = data.labels
        let filteredDataSets = data.datasets
        if (from.getDate() === new Date(defaultToDate).getDate() && to.getDate() === new Date(Date.now()).getDate()) {
            return initialData
        } else {
            const totalDays = Math.ceil((to.getTime() - from.getTime()) / (1000 * 60 * 60 * 24))
            const initialDays = 365
            const percentage = totalDays / initialDays

            const fakerValueKey = `${totalDays}_condition_days`
            let fakerValue = localStorage.getItem(fakerValueKey) as unknown as number
            if (!fakerValue) {
                const min = 0.1
                const max = 1
                fakerValue = Math.random() * (max - min) + min
                localStorage.setItem(fakerValueKey, fakerValue.toString())
            }

            filteredDataSets = data.datasets.map((dataset) => ({
                ...dataset,
                data: dataset.data.map((value) =>
                    Math.round(Number(value) * percentage * (1 - fakerValue))
                ) as number[],
            }))
        }

        const filteredChartData: ChartData = {
            labels: filteredLabels,
            datasets: filteredDataSets,
        }

        return filteredChartData
    }

    useEffect(() => {
        if (fromDate !== initialData.labels[0] || toDate !== defaultToDate) {
            const filteredData = filterData(initialData, fromDate, toDate)
            setChartData(filteredData)
        }
    }, [fromDate, toDate])

    return <Bar options={options} data={chartData} />
}

export default ConditionChart
