import { Button, Heading, Page, ProgressDots, Text } from '@psyomics/components'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './Demo.module.scss'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import DemoHeader from 'ui/app/components/DemoHeader'

const HealthcareExperiences: React.FC = () => {
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoHealthCareExperiences } = useMixpanelEvent(mixpanelApiUrl)

    useEffect(() => {
        visitedDemoHealthCareExperiences(undefined, undefined)
    }, [])

    return (
        <Page
            style={{ backgroundColor: 'transparent' }}
            layout="standard"
            width="standard"
            header={<DemoHeader navButtons={true} />}
        >
            <Heading el="h2" size="heading2" color="mid" css={{ mt: 7 }}>
                Dashboards
            </Heading>
            <Text css={{ mt: 2, mb: 7 }}>
                The following gives you an idea of the categories of data that would be produced by the usage of Censeo.
            </Text>
            <div className={Styles.managementGroup}>
                <div className={Styles.managementButtonWrapper}>
                    <Button
                        className="text-right"
                        appearance="primary"
                        rounded
                        size="large"
                        fullWidth={true}
                        label="Activity Dashboard"
                        onClick={() => {
                            navigate(`/demo/healthcare/activity-dashboard`)
                        }}
                    />
                </div>
                <Text css={{ ml: 3 }}>
                    This dashboard shows how the tool is performing and being used both by patients and your team.
                </Text>
            </div>
            <div className={Styles.managementGroup}>
                <div className={Styles.managementButtonWrapper}>
                    <Button
                        className="text-right"
                        appearance="primary"
                        rounded
                        size="large"
                        fullWidth={true}
                        label="Population Health"
                        onClick={() => {
                            navigate(`/demo/healthcare/population-health`)
                        }}
                    />
                </div>
                <Text css={{ ml: 3 }}>
                    This displays the clinical data gathered from your patients / service users to give you an overall
                    picture of your population.
                </Text>
            </div>
            <div className={Styles.managementGroup}>
                <div className={Styles.managementButtonWrapper}>
                    <Button
                        className="text-right"
                        appearance="primary"
                        rounded
                        size="large"
                        fullWidth={true}
                        label="Clinical Reports"
                        onClick={() => {
                            navigate(`/demo/healthcare/clinical-reports`)
                        }}
                    />
                </div>
                <Text css={{ ml: 3 }}>
                    This allows you to view the status of referred patients/service users along with example clinical
                    reports that Censeo would generate.
                </Text>
            </div>
            <ProgressDots css={{ mt: 5 }} count={5} current={5}></ProgressDots>
        </Page>
    )
}

export default HealthcareExperiences
