import React, { useState, useEffect, useContext } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import HealthcareExperiences from './HealthCareExperiences'
import PatientIntro from './PatientIntro'
import ExperienceChoice from './ExperienceChoice'
import DemoHome from './DemoHome'
import DemoIntroOverview from './DemoIntroOverview'
import DemoIntroAssessmentOutcome from './DemoIntroAssessmentOutcome'
import NotFound from 'ui/app/pages/NotFound'
import Stats from './Stats'
import ROI from './ROI'
import DemoCode from './DemoCode'
import ClinicalReports from './ClinicalReports'
import { InfoContext } from 'configure'
import { getCookie, setCookie } from 'registration/utils/cookies'
import * as Sentry from '@sentry/react'

type DemoRouterProps = React.ComponentPropsWithoutRef<'div'>

const DemoRoutes: React.FC<DemoRouterProps> = ({ ...props }) => {
    const location = useLocation()
    const info = useContext(InfoContext)
    const [demoType, setDemoType] = useState<'SHORT' | 'LONG'>('SHORT')
    const [isValid, setIsValid] = useState<boolean | null>(null)
    const [codeError, setCodeError] = useState<string | undefined>(undefined)
    const cookieName = 'demoAccessCode'

    const codeHandler = async (code: string, validate = true) => {
        const response = await fetch(`${info?.referralApiUrl}referrals/unauthenticated?demoCode=${code}`)
        const { type, locale } = await response.json()
        if (response.status === 200) {
            const daysToExpire = 28
            setCookie(cookieName, code, daysToExpire)
            setCookie('demoType', type, daysToExpire)
            setCookie('demoLocale', locale, daysToExpire)
            setIsValid(true)
            setDemoType(type)
        } else {
            setIsValid(false)
            if (validate) {
                if (response.status === 403) {
                    setCodeError('Invalid code.')
                } else {
                    Sentry.captureException(
                        `Error getting demoCode response - status ${response.status} ${response.statusText} code: ${code}`
                    )
                    setCodeError('The has been an error please contact support.')
                }
            }
        }
    }

    useEffect(() => {
        const demoCode = getCookie(cookieName)
        if (demoCode) {
            codeHandler(demoCode, false)
        } else {
            setIsValid(false)
        }
    }, [])

    if (!isValid) {
        const loadingDemoCode = isValid === null
        return <DemoCode codeHandler={codeHandler} codeError={codeError} loading={loadingDemoCode} />
    }
    return (
        <Routes location={location}>
            <Route path="/healthcare" element={<HealthcareExperiences />} />
            <Route path="/patient" element={<PatientIntro demoType={demoType} />} />
            <Route path="/experience" element={<ExperienceChoice />} />
            <Route path="/intro-overview" element={<DemoIntroOverview />} />
            <Route path="/intro-assessment-outcome" element={<DemoIntroAssessmentOutcome />} />
            <Route path="/healthcare/activity-dashboard" element={<ROI />} />
            <Route path="/healthcare/population-health" element={<Stats />} />
            <Route path="/healthcare/clinical-reports" element={<ClinicalReports />} />
            <Route path="/" element={<DemoHome />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default DemoRoutes
