import React from 'react'
import * as Sentry from '@sentry/react'
import Auth, { CognitoUser } from '@aws-amplify/auth'
import {
    CognitoUpdatableUserAttributes,
    CognitoUserAttributes,
    UserType,
    cognitoCustomAttributeNames,
    mapToUserFromAttributes,
} from 'censeo-core'
import { createContext, useCallback, useContext, useMemo, useReducer } from 'react'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { initialState, UpdatableUserAttributes } from './auth-reducer'
import { authReducer, AuthReducerAction, AuthState } from './auth-reducer'
import { MixpanelContext } from '../mixpanel/mixpanel-context'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'

export interface IUseAuth {
    state: AuthState
    dispatch: React.Dispatch<AuthReducerAction>
    initializeUser: () => Promise<void>
    signOutUser: () => Promise<void>
    getCurrentUser: () => Promise<
        | (CognitoUser & {
              attributes: CognitoUserAttributes
          })
        | undefined
    >
    updateUserAttributes: (attributesToUpdate: UpdatableUserAttributes) => Promise<void>
    requireConfirmation: (email: string, password: string) => Promise<void>
    askOnboardingQuestionsAgain: boolean
    hasAnsweredDemoQs: boolean
    referralCode: string | undefined
    isD2CUser: boolean
}

type AuthContextValue = [AuthState, React.Dispatch<AuthReducerAction>]

export const AuthContext = createContext<AuthContextValue | null>(null)

const AuthProvider: React.FC<{ children: React.ReactNode }> = (props) => {
    const [state, dispatch] = useReducer(authReducer, initialState)

    const value = useMemo(() => [state, dispatch], [state]) as AuthContextValue

    return <AuthContext.Provider value={value} {...props} />
}

// https://openid.net/specs/openid-connect-core-1_0.html#AddressClaim

export type CognitoUserAddress = {
    formatted?: string
    street_address?: string
    locality?: string
    region?: string
    postal_code?: string
    country?: string
}

// Original code and structure from https://github.com/bobbyhadz/aws-amplify-react-auth

function useAuth(): IUseAuth {
    const context = useContext(AuthContext)
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { loggedOut } = useMixpanelEvent(mixpanelApiUrl)
    const referralDetails = useContext(ReferralDetailsContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    const [state, dispatch] = context

    const getCurrentUser = useCallback(async () => {
        try {
            return (await Auth.currentAuthenticatedUser()) as CognitoUser & {
                attributes: CognitoUserAttributes
            }
        } catch (err) {
            Sentry.captureException(err)
        }
    }, [])

    const initializeUser = useCallback(async () => {
        try {
            const cognitoUser = await getCurrentUser()
            if (!cognitoUser) {
                dispatch({ type: 'UNAUTHENTICATED' })
                return
            }
            const user = {
                ...(cognitoUser.attributes ? mapToUserFromAttributes(cognitoUser.attributes) : {}),
                id: cognitoUser.getUsername(),
                postcode: cognitoUser.attributes?.address ? JSON.parse(cognitoUser.attributes.address).postal_code : '',
            }
            dispatch({
                type: 'LOGIN_SUCCESS',
                cognitoUser,
                user,
            })
        } catch (e) {
            if (e instanceof Error) {
                dispatch({ type: 'LOGIN_FAILURE', error: e })
            } else if (JSON.stringify(e).toLowerCase().includes('no current user')) {
                dispatch({ type: 'LOGIN_FAILURE', error: undefined })
            } else {
                dispatch({ type: 'LOGIN_FAILURE', error: new Error() })
            }
        }
    }, [dispatch, getCurrentUser])

    const signOutUser = useCallback(async () => {
        try {
            await Auth.signOut({ global: true })
            loggedOut(
                referralDetails?.data?.organisationCode,
                referralDetails?.data?.organisationName,
                referralDetails?.data?.publicId
            )
            dispatch({ type: 'LOGOUT_SUCCESS' })
        } catch (err) {
            Sentry.captureException(err)
            localStorage.clear()
        }
    }, [dispatch, loggedOut])

    const requireConfirmation = useCallback(
        async (email: string, password: string) => {
            dispatch({
                type: 'UNCONFIRMED_USER_LOGIN_ATTEMPT',
                emailAddress: email,
                password,
            })
        },
        [dispatch]
    )

    const updateUserAttributes = useCallback(
        async (attributesToUpdate: UpdatableUserAttributes) => {
            const cognitoAttributes = mapToCognitoUpdatableAttributes(attributesToUpdate)

            try {
                await Auth.updateUserAttributes(state.cognitoUser, cognitoAttributes)
                dispatch({
                    type: 'UPDATE_USER',
                    updatedUserAttributes: attributesToUpdate,
                })
            } catch (err) {
                Sentry.captureException(err)
                throw new Error(`Failed to update Cognito user attributes: ${err}`)
            }
        },
        [dispatch, state.cognitoUser]
    )

    const askOnboardingQuestionsAgain = state?.user?.referralPublicId !== referralDetails?.activeReferral?.publicId
    const hasAnsweredDemoQs = !!state?.user?.gender && !!state?.user?.ethnicity && !askOnboardingQuestionsAgain
    const referralCode = state?.user?.referralCode
    const isD2CUser = state?.user?.userType === UserType.D2C

    return {
        state,
        dispatch,
        initializeUser,
        signOutUser,
        getCurrentUser,
        updateUserAttributes,
        requireConfirmation,
        askOnboardingQuestionsAgain,
        hasAnsweredDemoQs,
        referralCode,
        isD2CUser,
    }
}

function mapToCognitoUpdatableAttributes(attributesToUpdate: UpdatableUserAttributes): CognitoUpdatableUserAttributes {
    const attributeEntries = Object.entries(attributesToUpdate)
    const attributesWithCustom = attributeEntries.map((e) => [getFieldNameWithCustomIfNeeded(e[0]), e[1]])
    return Object.fromEntries(attributesWithCustom)
}

function getFieldNameWithCustomIfNeeded(fieldName: string) {
    if (cognitoCustomAttributeNames.includes(fieldName)) return `custom:${fieldName}`
    return fieldName
}

export { AuthProvider, useAuth }
