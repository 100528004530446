"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSummaries = exports.renderFreeValue = exports.getAnswerSummaries = exports.groupSummary = exports.simpleSummary = exports.questionIds = exports.combinedAnswersSummary = exports.prefixOptionSummary = exports.multiOptionSummary = exports.showWhenNotSelected = exports.showWhenSelected = exports.GroupsType = void 0;
const censeo_1 = require("./schema/generated/censeo");
var GroupsType;
(function (GroupsType) {
    GroupsType["Max"] = "Max";
    GroupsType["Mean"] = "Mean";
})(GroupsType = exports.GroupsType || (exports.GroupsType = {}));
const showWhenSelected = (pattern, optionSummaries, tags) => ({
    type: 'ShowWhenSelected',
    pattern,
    matchFirstAnsweredQuestion: optionSummaries,
    tags,
});
exports.showWhenSelected = showWhenSelected;
const showWhenNotSelected = (questionId, pattern, optionSummaries) => ({
    type: 'ShowWhenNotSelected',
    questionId,
    pattern,
    optionSummaries,
});
exports.showWhenNotSelected = showWhenNotSelected;
const multiOptionSummary = (questionId, title, overrides, tags) => ({
    type: 'MultiOptionSummary',
    questionId,
    title,
    overrides,
    tags,
});
exports.multiOptionSummary = multiOptionSummary;
const prefixOptionSummary = (questionIds, prefix, formatText, tags) => ({
    type: 'PrefixOptionSummary',
    questionIds,
    prefix,
    formatText,
    tags,
});
exports.prefixOptionSummary = prefixOptionSummary;
const combinedAnswersSummary = (questionsIdGroups, groupsType, pattern, coefficients, tags) => ({
    type: 'CombinedAnswersSummary',
    questionsIdGroups,
    groupsType,
    pattern,
    coefficients,
    tags,
});
exports.combinedAnswersSummary = combinedAnswersSummary;
const questionIds = (answerSummary) => {
    if (answerSummary.type === 'ShowWhenSelected') {
        return answerSummary.matchFirstAnsweredQuestion.map((a) => a.questionId);
    }
    if (answerSummary.type === 'PrefixOptionSummary') {
        return answerSummary.questionIds;
    }
    if (answerSummary.type === 'CombinedAnswersSummary') {
        return answerSummary.questionsIdGroups.flat();
    }
    return [answerSummary.questionId];
};
exports.questionIds = questionIds;
const simpleSummary = (text) => ({
    type: 'SimpleSummary',
    text,
});
exports.simpleSummary = simpleSummary;
const groupSummary = (title, summaries) => ({
    type: 'GroupSummary',
    title,
    summaries,
});
exports.groupSummary = groupSummary;
const getAnswerSummaries = (questionsById, answers, answerSummaries) => {
    const answeredQuestionsById = answers.reduce((acc, next) => {
        return { ...acc, [next.questionId]: next };
    }, {});
    return answerSummaries.reduce((acc, next) => {
        let answers;
        if (next.type === 'ShowWhenSelected') {
            answers = next.matchFirstAnsweredQuestion.map(({ questionId }) => answeredQuestionsById[questionId]);
        }
        else if (next.type === 'PrefixOptionSummary') {
            answers = next.questionIds.map((questionId) => answeredQuestionsById[questionId]);
        }
        else if (next.type === 'CombinedAnswersSummary') {
            answers = Object.values(answeredQuestionsById);
        }
        else {
            answers = [answeredQuestionsById[next.questionId]];
        }
        if (next.type === 'CombinedAnswersSummary') {
            const result = getSymptomResult(questionsById, answers, next.groupsType, next.questionsIdGroups);
            if (result !== undefined) {
                const coefficients = Object.keys(next.coefficients);
                const coefficient = getCoefficient(coefficients, result);
                if (coefficient !== undefined && coefficients.includes(coefficient.toString())) {
                    const match = (0, exports.simpleSummary)(next.pattern.replace('{}', next.coefficients[coefficient]));
                    return [match, ...acc];
                }
            }
            return acc || [];
        }
        else {
            const summaries = answers.filter((x) => x).map((answer) => getSummary(questionsById, answer, next));
            const firstMatch = summaries.find((summaries) => summaries.length > 0);
            return [...(firstMatch || []), ...acc];
        }
    }, []);
};
exports.getAnswerSummaries = getAnswerSummaries;
const getSymptomResult = (questionsById, answers, groupsType, idGroups) => {
    if (answers.length === 0) {
        return undefined;
    }
    const totalsByGroup = getGroupedTotals(idGroups, questionsById, answers);
    const symptomResult = getOverallResult(totalsByGroup, groupsType, idGroups);
    return symptomResult;
};
const getCoefficient = (coefficients, result) => {
    let coefficient = coefficients.map((y) => Number(y)).find((x) => x === result);
    if (coefficient === undefined && coefficients.length > 1) {
        coefficient = coefficients
            .map((x) => Number(x))
            .sort((a, b) => (a < b ? 1 : -1))
            .find((element) => element < result);
    }
    return coefficient;
};
const getSummary = (questionsById, answer, answerSummary) => {
    var _a, _b, _c, _d;
    const chosenOptionIds = answer.chosenOptionIds;
    if ((answerSummary.type === 'ShowWhenSelected' || answerSummary.type === 'ShowWhenNotSelected') &&
        chosenOptionIds) {
        const showWhenSelectedOptionSummaries = answerSummary.type === 'ShowWhenSelected'
            ? (_a = answerSummary.matchFirstAnsweredQuestion.find((x) => x.questionId === answer.questionId)) === null || _a === void 0 ? void 0 : _a.optionSummaries
            : {};
        const optionSummarries = answerSummary.type === 'ShowWhenSelected'
            ? chosenOptionIds
                .map((id) => showWhenSelectedOptionSummaries && showWhenSelectedOptionSummaries[id])
                .filter((summary) => summary !== undefined)
            : Object.entries(answerSummary.optionSummaries)
                .filter(([id, _]) => !chosenOptionIds.includes(id))
                .map(([_, value]) => value);
        return optionSummarries.map((summary) => (0, exports.simpleSummary)(answerSummary.pattern.replace('{}', summary)));
    }
    if (answerSummary.type === 'MultiOptionSummary' && questionsById[answer.questionId]) {
        const question = questionsById[answerSummary.questionId];
        if (question.__typename !== 'RestrictedChoiceQuestion') {
            return [];
        }
        const summaries = ((_b = answer.chosenOptionIds) === null || _b === void 0 ? void 0 : _b.map((optionId) => {
            var _a;
            return answerSummary.overrides[optionId] ||
                ((_a = question.answers.find((option) => option.id === optionId)) === null || _a === void 0 ? void 0 : _a.text);
        }).filter((summary) => summary !== undefined)) || [];
        return summaries.length ? [(0, exports.groupSummary)(answerSummary.title, summaries)] : [];
    }
    if (answerSummary.type === 'PrefixOptionSummary' && questionsById[answer.questionId]) {
        const question = questionsById[answer.questionId];
        let chosenOption;
        if (question.__typename === 'RestrictedChoiceQuestion') {
            chosenOption = (_c = question.answers.find((option) => { var _a; return option.id === ((_a = answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a[0]); })) === null || _c === void 0 ? void 0 : _c.text;
        }
        else if (question.__typename === 'FreeQuestion') {
            chosenOption =
                question.unit && answer.freeValue !== undefined && answer.freeValue !== null
                    ? (0, exports.renderFreeValue)(question.unit, answer.freeValue)
                    : undefined;
        }
        else if (question.__typename === 'FreeTextQuestion') {
            chosenOption = answer.freeText;
        }
        if (chosenOption) {
            if ((_d = answerSummary.formatText) === null || _d === void 0 ? void 0 : _d.length) {
                chosenOption = answerSummary.formatText.replace('{}', chosenOption);
            }
            return [(0, exports.simpleSummary)(`${answerSummary.prefix}${chosenOption}`)];
        }
        return [];
    }
    return [];
};
const renderFreeValue = (unit, value) => {
    switch (unit) {
        case censeo_1.Unit.DurationHours:
        case censeo_1.Unit.DurationMinutes:
            if (value === 0)
                return '0 days';
            if (value >= 365)
                return `${maxOneDecimalPlace(value / 365)} year(s)`;
            if (value >= 28)
                return `${maxOneDecimalPlace(value / 28)} month(s)`;
            if (value >= 7)
                return `${maxOneDecimalPlace(value / 7)} week(s)`;
            if (value >= 1)
                return `${maxOneDecimalPlace(value)} day(s)`;
            if (value >= 1 / 24)
                return `${maxOneDecimalPlace(24 * value)} hour(s)`;
            if (value >= 1 / (24 * 60))
                return `${maxOneDecimalPlace(24 * 60 * value)} minute(s)`;
            return `${maxOneDecimalPlace(24 * 60 * 60 * value)} second(s)`;
        case censeo_1.Unit.Age:
            return `${value} years old`;
        case censeo_1.Unit.None:
            return `${value}`;
        default:
            return undefined;
    }
};
exports.renderFreeValue = renderFreeValue;
const maxOneDecimalPlace = (x) => parseFloat(x.toFixed(1));
const getSummaries = (questions, summariesByAspectName, answeredQuestions, aspectName) => {
    const sectionDelimiter = '|';
    // Let's use a deep copy of these for the report otherwise modifying these will potentially impact the algo outputs
    const summariesCopy = JSON.parse(JSON.stringify(summariesByAspectName[aspectName].summaries));
    const answeredQuestionsCopy = JSON.parse(JSON.stringify(answeredQuestions));
    const summariesGroupedBySection = summariesCopy.reduce((acc, next) => {
        const encodedSection = next.section
            .map((s) => {
            if (typeof s === 'object' && 'conditionalTitle' in s) {
                const { possibleTexts, questionId } = s.conditionalTitle;
                const question = answeredQuestionsCopy.find((q) => q.questionId === questionId);
                if (question && question.chosenOptionIds && possibleTexts.length > 0) {
                    const foundPossibleText = possibleTexts.find((pt) => pt.optionIds.some((optId) => question.chosenOptionIds.includes(optId)));
                    return foundPossibleText ? foundPossibleText.text : s.altTitle;
                }
                else {
                    // If no answer found, just return an alternative text defined in the symptom json files
                    return s.altTitle;
                }
            }
            else
                return s;
        })
            .join(sectionDelimiter);
        return {
            ...acc,
            [encodedSection]: [next.answerSummary, ...(acc[encodedSection] || [])],
        };
    }, {});
    const questionsById = questions.reduce((acc, next) => ({
        ...acc,
        [next.id]: next,
    }), {});
    return Object.entries(summariesGroupedBySection).reduce((acc, [header, summaries]) => [
        ...acc,
        {
            header: header.split(sectionDelimiter).filter((x) => x),
            summaries: (0, exports.getAnswerSummaries)(questionsById, answeredQuestionsCopy, summaries),
        },
    ], []);
};
exports.getSummaries = getSummaries;
const getGroupedTotals = (idGroups, questionsById, answers) => {
    const totals = new Map();
    const answeredQuestionIds = answers.map((x) => x.questionId);
    idGroups.forEach((group, index) => {
        group.forEach((questionId) => {
            var _a, _b, _c;
            if (answeredQuestionIds.includes(questionId)) {
                const questionOfAnyType = questionsById[questionId];
                const question = (questionOfAnyType === null || questionOfAnyType === void 0 ? void 0 : questionOfAnyType.__typename) === 'RestrictedChoiceQuestion'
                    ? questionOfAnyType
                    : undefined;
                const answeredQuestion = answers.find((y) => y.questionId === questionId);
                if ((_a = answeredQuestion === null || answeredQuestion === void 0 ? void 0 : answeredQuestion.chosenOptionIds) === null || _a === void 0 ? void 0 : _a.length) {
                    const chosenOptionId = answeredQuestion.chosenOptionIds[0];
                    const signal = (_c = (_b = question === null || question === void 0 ? void 0 : question.answers) === null || _b === void 0 ? void 0 : _b.find((x) => x.id === chosenOptionId)) === null || _c === void 0 ? void 0 : _c.value;
                    const groupTotal = totals.get(index);
                    if (groupTotal === undefined) {
                        totals.set(index, signal);
                    }
                    else {
                        totals.set(index, groupTotal + signal);
                    }
                }
            }
        });
    });
    return totals;
};
function getOverallResult(groupedTotals, groupsType, idGroups) {
    let result = 0;
    if (groupedTotals.size) {
        switch (groupsType) {
            case GroupsType.Mean: {
                let allGroupedTotals = 0;
                idGroups.forEach((idGroup, index) => {
                    const groupTotal = groupedTotals.get(index);
                    if (groupTotal !== undefined) {
                        allGroupedTotals += groupTotal / idGroup.length;
                    }
                });
                result = allGroupedTotals / groupedTotals.size;
                break;
            }
            case GroupsType.Max: {
                const values = Array.from(groupedTotals.values());
                result = Math.max(...values);
                break;
            }
            default: {
                throw new Error('not supported');
            }
        }
    }
    return result;
}
