import { Button, Page, Text, ProgressDots, Heading } from '@psyomics/components'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './Demo.module.scss'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import DemoHeader from 'ui/app/components/DemoHeader'

const DemoIntroOverview: React.FC = () => {
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoIntro } = useMixpanelEvent(mixpanelApiUrl)

    useEffect(() => {
        visitedDemoIntro(undefined, undefined)
    }, [])

    return (
        <Page
            style={{ backgroundColor: 'transparent' }}
            layout="standard"
            width="narrow"
            header={<DemoHeader navButtons={false} />}
        >
            <Heading el="h2" size="heading2" color="mid" css={{ mb: 2 }}>
                What is Censeo
            </Heading>
            <Text size="medium" css={{ mb: 5 }}>
                Censeo is an online mental health triage, assessment and pathway planning tool, which allows assessments
                to be conducted without healthcare professionals being present.
            </Text>

            <Text size="medium" css={{ mb: 5 }}>
                A questionnaire is completed by patients or service users, at a time and place that suits them. The
                questions are personalised and adapted based on the answers to previous questions.
            </Text>

            <ProgressDots count={5} current={2}></ProgressDots>
            <div className={Styles.btnWrapper}>
                <Button
                    className="text-right"
                    appearance="primary"
                    rounded
                    size="large"
                    label="Continue"
                    onClick={() => {
                        navigate(`/demo/intro-assessment-outcome`)
                    }}
                />
            </div>
        </Page>
    )
}

export default DemoIntroOverview
