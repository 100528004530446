import React from 'react'
import { useNavigate } from 'react-router-dom'
import AccountDetails from 'ui/app/components/AccountDetails'
import MenuItem from 'ui/app/components/MenuItem'
import ContentPage from '../components/ContentPage'
import { useAuth } from 'registration/context/auth'

export const UserProfile: React.FC = () => {
    const navigate = useNavigate()
    const { isD2CUser } = useAuth()

    const onHome = () => {
        navigate('/welcome')
    }

    return (
        <ContentPage headingText="My Account" showAccountLink={false} headingButton={{ text: 'Home', link: onHome }}>
            <AccountDetails />
            <MenuItem to={'/regulatory-information'} label="About this app" />
            <MenuItem href="https://www.psyomics.com/termsofuse" label="Terms of Use" />
            <MenuItem href="https://www.psyomics.com/privacy-policy" label="Privacy Policy" />
            <MenuItem href="https://www.psyomics.com/cookie-policy" label="Cookie Policy" />
            {isD2CUser && <MenuItem to={'/refund-policy'} label="Refund Policy" />}
        </ContentPage>
    )
}
