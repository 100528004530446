import React from 'react'
import { Widget } from '@typeform/embed-react'
import Styles from './TypeformEmbed.module.scss'

interface TypeformEmbedProps {
    contract: string
}

export const TypeformEmbed: React.FC<TypeformEmbedProps> = ({ contract }) => {
    return <Widget id="eltxP25H" className={Styles.typeformFeedback} hidden={{ contract }}></Widget>
}
