import React from 'react'
import { Page, Text, Button, Link } from '@psyomics/components'
import { useNavigate } from 'react-router-dom'
import Header from 'registration/components/Header'
import { classNameMaker } from 'ui/utils'
import Styles from './Error.module.scss'
import { useAuth } from 'registration/context/auth'

interface ErrorProps {
    customTitle?: string
    customMessage?: string
    homepageButton?: boolean
    homepageRedirect?: string
    logOutButton?: boolean
}

const Error: React.FC<ErrorProps> = ({
    customTitle,
    customMessage,
    homepageButton = true,
    logOutButton = false,
    homepageRedirect,
}) => {
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })

    const { signOutUser } = useAuth()
    const navigate = useNavigate()

    return (
        <Page>
            <Header template="landing" />
            <div className={containerClass}>
                <Text size="large" color="mid" role="heading">
                    {customTitle ? customTitle : 'Unexpected error'}
                </Text>
            </div>

            {customMessage ? (
                <Text css={{ mt: 4 }}>{customMessage}</Text>
            ) : (
                <>
                    <Text css={{ mt: 4 }}>Something went wrong.</Text>
                    <Text css={{ mt: 2 }}>Try to refresh this page or go to the homepage.</Text>
                </>
            )}
            <Text css={{ mt: 2 }}>
                Alternatively, please feel free to contact us on{' '}
                <Link href="mailto:support@censeo.co.uk">support@censeo.co.uk</Link> if the problem continues.
            </Text>

            {homepageButton && (
                <div className={Styles.formActions}>
                    <Button
                        css={{ mt: 4 }}
                        appearance="primary"
                        rounded
                        fullWidth
                        size="large"
                        label="Homepage"
                        onClick={() => {
                            navigate(homepageRedirect ?? '/')
                        }}
                    />
                </div>
            )}
            {logOutButton && (
                <div className={Styles.formActions}>
                    <Button
                        css={{ mt: 4 }}
                        appearance="primary"
                        rounded
                        fullWidth
                        size="large"
                        label="Sign out"
                        onClick={async () => {
                            await signOutUser()
                            navigate('/')
                        }}
                    />
                </div>
            )}
        </Page>
    )
}

export default Error
