import React, { useContext, useEffect, useState } from 'react'
import { Page, Heading, Logo } from '@psyomics/components'
import Content from 'registration/components/Content'
import {
    OnboardingInputsInitialValues,
    OnboardingFormProps,
    OnboardingFormSchema,
    OnboardingFormStage,
} from './use-Onboarding'
import { Formik } from 'formik'
import Gender from './Gender'
import Ethnicity from './Ethnicity'
import Sex from './Sex'
import { useAuth } from 'registration/context/auth'
import { useAsync } from 'registration/hooks/use-async'
import { useNavigate } from 'react-router-dom'
import Styles from '../SignUp/SignUp.module.scss'
import Name from '../SignUp/Name'
import AboutYou from '../SignUp/AboutYou'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'
import { Splash } from 'ui/app/pages/Splash'

type IOnboarding = React.ComponentPropsWithoutRef<'div'> & {
    setReferralPublicId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Onboarding: React.FC<IOnboarding> = ({ setReferralPublicId }) => {
    const formSchema = OnboardingFormSchema

    // todo: deal with any questions already being answered.
    const { state, hasAnsweredDemoQs, updateUserAttributes } = useAuth()

    const referralDetails = useContext(ReferralDetailsContext)
    const isLoadingReferralContext = referralDetails?.status === 'loading' || referralDetails?.status === 'pending'

    const sex = state.user?.sex
    const gender = state.user?.gender
    const formInitialValues = {
        ...OnboardingInputsInitialValues,
        ...(gender ? { gender } : {}),
        ...(sex ? { sex } : {}),
    }

    const [pageState, setPageState] = useState<OnboardingFormStage>('name')

    const { run } = useAsync()

    const navigate = useNavigate()
    useEffect(() => {
        if (hasAnsweredDemoQs) {
            navigate('/welcome')
        }
    }, [hasAnsweredDemoQs, history])

    const pageProps = {
        className: Styles.page,
        layout: 'standard',
        width: 'narrow',
        header: (
            <header className={Styles.header}>
                <div className={Styles.headerOuter}>
                    <div className={Styles.headerInner}>
                        <Logo className={Styles.largeCenseoLogo} height="56" width="190" />
                    </div>
                </div>
            </header>
        ),
    }

    return (
        <>
            {isLoadingReferralContext && <Splash />}
            {!isLoadingReferralContext && (
                <Page {...pageProps}>
                    <Content>
                        <Heading el="h1" size="heading2" color="mid" css={{ mb: 4 }}>
                            Further details
                        </Heading>
                        <Formik
                            initialValues={formInitialValues}
                            validationSchema={formSchema}
                            onSubmit={async (values) => {
                                await run(
                                    updateUserAttributes({
                                        sex: values.sex,
                                        gender: values.gender,
                                        ethnicity: values.ethnicity,
                                        given_name: values.firstName.trim(),
                                        family_name: values.lastName.trim(),
                                        birthdate: values.birthdate,
                                        address: JSON.stringify({ postal_code: values.postcode }),
                                        ...(referralDetails?.activeReferral?.publicId && {
                                            referralPublicId: referralDetails?.activeReferral?.publicId,
                                        }),
                                    })
                                )

                                setReferralPublicId(referralDetails?.activeReferral?.publicId)
                            }}
                        >
                            {(formikProps) => (
                                <form onSubmit={formikProps.handleSubmit}>
                                    <View
                                        values={formikProps.values}
                                        errors={formikProps.errors}
                                        touched={formikProps.touched}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        validateForm={formikProps.validateForm}
                                        submitForm={formikProps.submitForm}
                                        pageState={pageState}
                                        setPageState={setPageState}
                                        setFieldValue={formikProps.setFieldValue}
                                    />
                                </form>
                            )}
                        </Formik>
                    </Content>
                </Page>
            )}
        </>
    )
}

type ViewProps = OnboardingFormProps & { pageState: OnboardingFormStage } & React.ComponentPropsWithoutRef<'div'>

const View: React.FC<ViewProps> = ({
    values,
    errors,
    touched,
    onChange,
    onBlur,
    validateForm,
    submitForm,
    pageState,
    setPageState,
    setFieldValue,
}) => {
    switch (pageState) {
        case 'name':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <Name
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={onChange}
                        onBlur={onBlur}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        setPageState={setPageState}
                        setFieldValue={setFieldValue}
                    />
                </React.Suspense>
            )
        case 'postcode':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <AboutYou
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={onChange}
                        onBlur={onBlur}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        setPageState={setPageState}
                        setFieldValue={setFieldValue}
                    />
                </React.Suspense>
            )
        case 'sex':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <Sex
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={onChange}
                        onBlur={onBlur}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        setPageState={setPageState}
                        setFieldValue={setFieldValue}
                    />
                </React.Suspense>
            )
        case 'gender':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <Gender
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={onChange}
                        onBlur={onBlur}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        setPageState={setPageState}
                        setFieldValue={setFieldValue}
                    />
                </React.Suspense>
            )
        case 'ethnicity':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <Ethnicity
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={onChange}
                        onBlur={onBlur}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        setPageState={setPageState}
                        setFieldValue={setFieldValue}
                    />
                </React.Suspense>
            )
        default:
            return <></>
    }
}

export default Onboarding
