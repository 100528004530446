import '@psyomics/components/assets/fonts/webfonts.css'
import '@psyomics/components/global.css'
import './index.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ApolloProvider } from '@apollo/client'
import { client } from './data/GraphQLClient'
import { AuthProvider } from './registration/context/auth'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthenticatedApp } from './AuthenticatedApp'
import { toAbsolutePath } from 'hostOverride'
import { Info, Infrastructure } from 'configure'
import { AuthenticatedAppPractitioner } from 'AuthenticatedAppPractitioner'
import { MixpanelProvider } from 'registration/context/mixpanel/mixpanel-provider'

const runBeforeRender = async () => {
    const response = await fetch(toAbsolutePath('/info'))
    const info: Info & Infrastructure = await response.json()
    const isProdLikeOrStagingEnv =
        import.meta.env.environment === 'production' ||
        import.meta.env.environment === 'staging' ||
        import.meta.env.environment === 'pentest'

    const isSentryDisabled = import.meta.env.VITE_APP_SENTRY_DISABLED === 'true'

    if (!isSentryDisabled) {
        Sentry.init({
            dsn: 'https://00c949ce9e4efdaef03acf996941b409@o4507389823156224.ingest.de.sentry.io/4507426475016272',
            environment: info.environment,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: import.meta.env.VITE_APP_LOCAL_DEVELOPMENT_INFO_HOST ? 1.0 : 0.2,
            replaysOnErrorSampleRate: 1.0,
            integrations: isProdLikeOrStagingEnv ? [new BrowserTracing(), new Sentry.Replay()] : [new BrowserTracing()],
        })
    } else {
        console.log('Sentry is disabled')
    }
}

runBeforeRender()

const domNode = document.getElementById('root')
if (!domNode) throw Error('no root node')
const root = createRoot(domNode)

root.render(
    <React.StrictMode>
        <AuthProvider>
            <MixpanelProvider>
                <ApolloProvider client={client}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/report/*" element={<AuthenticatedAppPractitioner />} />
                            <Route path="/account/*" element={<AuthenticatedAppPractitioner />} />
                            <Route path="/practitioner-callback/*" element={<AuthenticatedAppPractitioner />} />
                            <Route path="/psyomics-login/*" element={<AuthenticatedAppPractitioner />} />
                            <Route path="/practitioner-auth/*" element={<AuthenticatedAppPractitioner />} />
                            <Route path="/*" element={<AuthenticatedApp />} />
                        </Routes>
                    </BrowserRouter>
                </ApolloProvider>
            </MixpanelProvider>
        </AuthProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
