import React, { useContext, useEffect, useState } from 'react'
import RiskChart from './RiskChart'
import { Button, Heading, Page } from '@psyomics/components'
import ConditionChart from './ConditionChart'
import { Splash } from 'ui/app/pages/Splash'
import Styles from './Stats.module.scss'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import TopNavBar from 'ui/app/components/TopNavBar'

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export interface ChartProps {
    fromDate: string
    toDate: string
    defaultToDate: string
}

const Stats: React.FC = () => {
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoStats } = useMixpanelEvent(mixpanelApiUrl)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    useEffect(() => {
        loadData()
    })

    useEffect(() => {
        visitedDemoStats(undefined, undefined)
    }, [])

    const loadData = async () => {
        await delay(2000)
        setIsLoaded(true)
    }
    const defaultFromDate = '2023-01-01'
    const [fromDate, setFromDate] = useState<string>(defaultFromDate)

    const defaultToDate = new Date().toISOString().split('T')[0]
    const [toDate, setToDate] = useState<string>(defaultToDate)

    const chartProps: ChartProps = {
        fromDate: fromDate,
        toDate: toDate,
        defaultToDate,
    }

    const handleDateReset = () => {
        setFromDate(defaultFromDate)
        setToDate(defaultToDate)
    }

    if (isLoaded) {
        return (
            <Page width="wide">
                <TopNavBar />
                <Heading el="h1" size="heading1" color="mid" css={{ mb: 2 }}>
                    Population Health
                </Heading>
                <div>
                    <div className={Styles.nav}>
                        <div>
                            <label>From:</label>
                            <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                        </div>
                        <div>
                            <label>To:</label>
                            <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                        </div>
                        <div>
                            <Button appearance="primary" size="small" label="Reset" onClick={handleDateReset}></Button>
                        </div>
                    </div>
                </div>
                <Heading el="h2" size="heading2" color="mid" css={{ mb: 4, mt: 4 }}>
                    Condition Coverage
                </Heading>
                <div className={Styles.condition}>
                    <ConditionChart {...chartProps} />
                </div>
                <Heading el="h2" size="heading2" color="mid" css={{ mb: 4, mt: 4 }}>
                    Triage Priority
                </Heading>
                <div className={Styles.risk}>
                    <RiskChart {...chartProps} />
                </div>
            </Page>
        )
    } else {
        return <Splash />
    }
}

export default Stats
