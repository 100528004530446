import React, { useState, useEffect, useRef, useContext } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import Hotjar from '@hotjar/browser'
import { Splash } from './Splash'
import {
    Maybe,
    QueryReportDetailsArgs,
    ReportDetails,
    AllExistingReports,
    ReportResultMessage,
    UserType,
} from 'censeo-core'

import { FetchJsonReportData, fetchJsonReportQuery } from 'data/queries/fetchJsonReport'
import { useParams } from 'react-router-dom'
import CenseoError from './Error'
import NotFound from './NotFound'
import * as Sentry from '@sentry/react'
import { useAuth } from 'registration/context/auth'
import { TabLabel, TabLabelD2C, Page, Heading, ReportTabs, Logo, Button, Modal } from '@psyomics/components'
import { ReportNav } from './ReportNav'
import Styles from './LoadReportV2.module.scss'
import { IPageProps } from '@psyomics/components/components/Page/Page'
import { ReportDetailsData, reportDetailsQuery } from 'data/queries/reportDetails'
import HelpModal from '../components/HelpModal'
import { demoReports } from 'registration/pages/Demo/ClinicalReports/fakeData'
import { InfoContext } from 'configure'
import { posthogCapture } from 'ui/utils/posthog'
import { downloadFromLink, usePdfDownload } from '../hooks/usePdfDownload'
import { getLocale } from 'registration/utils/locale'

async function fetchDemoReport(reportUrl: string): Promise<ReportDetails | undefined> {
    const response = await fetch(reportUrl)
    const json = await response.json()
    if (!response.ok) {
        Sentry.captureException(new Error('Response not ok'), {
            extra: {
                responseCode: response.status,
                responseContent: json,
            },
            tags: { section: 'Load report' },
        })
        return undefined
    }
    return json
}

const Disclaimer: React.FC<{ patientFacing: boolean }> = ({ patientFacing }) => {
    return patientFacing ? (
        <>
            Notice: this report gives information only, not a diagnosis. <br />
            It doesn’t replace medical advice or support. If you are worried about your mental health, please talk to a
            doctor.
        </>
    ) : (
        <>
            This report provides informational support based on patient self-reports at a specific point in time and is
            not a diagnostic report or risk assessment. <br />
            Clinicians should integrate these insights with other clinical information. Censeo informs but does not
            replace comprehensive clinical evaluations, and patient needs may change over time.
        </>
    )
}

export const LoadReportV2: React.FC<{ patientFacing?: boolean }> = ({ patientFacing = false }) => {
    const demoLocale = getLocale()
    const info = useContext(InfoContext)

    let { id } = useParams<{ id: string }>()
    if (id === undefined) {
        // Extract report id from URL in the case when loading a Demo report.
        // Demo Routes are fixed string which include the allowed demo id
        // e.g. report/0f860b00-9c9c-4db4-8877-51a312352090
        // Hence we do not have :id defined for these routes to be able
        // to use useParams()
        id = window.location.pathname.split('/').slice(-1)[0]
    }

    const posthogEventSent = useRef(false)
    const isDemoAssessment = demoReports.ids.includes(id)
    const scrollingElementRef = useRef<HTMLDivElement>(null)
    const { getCurrentUser } = useAuth()
    const [currentUserId, setCurrentUserId] = useState<string | undefined>()
    const [userType, setUserType] = useState<Maybe<string> | undefined>()
    const [demoReport, setDemoReport] = useState<ReportDetails | undefined>()
    const [skipPdf, setSkipPdf] = useState<boolean>(false)
    const [isAuthorisedForPatient, setIsAuthorisedForPatient] = useState<boolean | undefined>(undefined)

    const pageProps = {
        layout: 'standard',
        width: 'x-wide',
        scrollingElementRef,
        header: (
            <header className={Styles.header}>
                <div>
                    <span className={Styles.helpAndLogo}>
                        {userType === UserType.D2C && (
                            <>
                                <span className={Styles.helpModal}>
                                    <HelpModal></HelpModal>
                                </span>
                                <span className={Styles.helpModalDivider}></span>
                            </>
                        )}
                        <span className={Styles.logoPadder}>
                            <Logo className={Styles.compositeLogo} height="32" format="composite" />
                            <Logo className={Styles.logomark} height="32" format="logomark" />
                        </span>
                    </span>

                    <div className={`${Styles.disclaimer} ${Styles.desktop}`}>
                        <Disclaimer patientFacing={patientFacing}></Disclaimer>
                    </div>

                    <Heading className={Styles.desktop} color="mid" size="heading1" style={{ justifySelf: 'end' }}>
                        Mental Health Report
                    </Heading>
                    <Heading className={Styles.mobile} color="mid" size="heading3" style={{ justifySelf: 'end' }}>
                        Mental Health Report
                    </Heading>
                </div>
                <div className={`${Styles.disclaimer} ${Styles.mobile}`}>
                    <Disclaimer patientFacing={patientFacing}></Disclaimer>
                </div>
            </header>
        ),
    } as IPageProps

    useEffect(() => {
        const hotjarSiteId = import.meta.env.VITE_APP_HOTJAR_REPORT_V2_SITE_ID
        if (hotjarSiteId) {
            Hotjar.init(Number(hotjarSiteId), 6)
        }
    }, [])

    useEffect(() => {
        ;(async () => {
            const user = await getCurrentUser()
            const userId = user?.getUsername()
            if (userId) {
                setCurrentUserId(userId)
            } else {
                Sentry.captureException(new Error('Failed to load report'), {
                    extra: {
                        detail: 'Unable to get user id',
                    },
                    tags: { section: 'Load report', reportVersion: 2 },
                })
            }
        })()
    }, [getCurrentUser])

    const {
        loading: dbReportLoading,
        data: dbReportData,
        error: dbReportError,
    } = useQuery<FetchJsonReportData, QueryReportDetailsArgs>(fetchJsonReportQuery, {
        variables: { id },
    })

    const [getReportDetails, { loading: genReportLoading, data: genReportData, error: genReportError }] = useLazyQuery<
        ReportDetailsData,
        QueryReportDetailsArgs
    >(reportDetailsQuery)

    const [activeTab, setActiveTab] = useState<TabLabel | TabLabelD2C>(TabLabel.Summary)
    const handleTabClick = (newTab: TabLabel | TabLabelD2C) => {
        setActiveTab(newTab)
        scrollingElementRef.current?.scrollTo({ behavior: 'smooth', left: 0, top: 0 })
    }

    const [clickedConditionCode, setClickedConditionCode] = useState('')
    const handleNavigateToConditions = (conditionCode?: string) => {
        if (conditionCode) {
            setClickedConditionCode(conditionCode)
        }
        setActiveTab(TabLabel.ConditionDetails)
    }

    useEffect(() => {
        const isError = dbReportError || genReportError
        const isNotFound =
            dbReportData?.fetchJsonReport?.message === ReportResultMessage.NotFound &&
            genReportData?.reportDetails?.message === ReportResultMessage.NotFound
        const isAccessDenied =
            dbReportData?.fetchJsonReport?.message === ReportResultMessage.AccessDenied ||
            genReportData?.reportDetails?.message === ReportResultMessage.AccessDenied
        if (isError || isNotFound || isAccessDenied) {
            setSkipPdf(true)
        }
    }, [dbReportError, genReportError, dbReportData, genReportData])

    const { foundPdf, pdfErrorToShow, closePdfError, handlePdfDownloadClick } = usePdfDownload({
        id,
        skip: skipPdf,
        patientFacing,
    })

    useEffect(() => {
        if (!id) {
            Sentry.captureException('No id found for report')
            return
        }
        if (isDemoAssessment) {
            ;(async () => {
                const res = await fetchDemoReport(demoReports.location(id))
                setDemoReport(res)
            })().catch((error) => {
                Sentry.captureException(error, { tags: { section: 'Failed to load demo report V2' } })
            })
        } else if (
            !dbReportLoading &&
            dbReportData?.fetchJsonReport.message === ReportResultMessage.NotFound &&
            !genReportData
        ) {
            getReportDetails({ variables: { id } })
        }
    }, [dbReportLoading, dbReportData, getReportDetails, id, isDemoAssessment])

    let reportDetails: Maybe<ReportDetails> | undefined
    let allCompletedReportsForUser: Maybe<Maybe<AllExistingReports>[]> | undefined
    if (isDemoAssessment) {
        reportDetails = demoReport
    } else if (dbReportData?.fetchJsonReport.message === ReportResultMessage.Success) {
        reportDetails = dbReportData.fetchJsonReport.reportDetails
        allCompletedReportsForUser = dbReportData.fetchJsonReport.allExistingReports
    } else if (genReportData?.reportDetails.message === ReportResultMessage.Success) {
        reportDetails = genReportData.reportDetails.reportDetails
    }

    useEffect(() => {
        if (reportDetails?.demographics) {
            setUserType(reportDetails.demographics.userType)
        }
        if (reportDetails?.organisationConfig?.patientReport && reportDetails.isAuthorisedForPatient !== null) {
            setIsAuthorisedForPatient(reportDetails.isAuthorisedForPatient)
        }
    }, [reportDetails])

    useEffect(() => {
        ;(async () => {
            if (!posthogEventSent.current && reportDetails && reportDetails.demographics) {
                try {
                    const orgResponse = await fetch(
                        `${info?.referralApiUrl}referrals/unauthenticated?assessmentId=${id}`
                    )

                    if (!orgResponse.ok) {
                        throw new Error('Failed to fetch org data or posthog capture for report V2')
                    }
                    const orgData = await orgResponse.json()
                    const clinicianUserId = (await getCurrentUser())?.getUsername()

                    posthogCapture(info?.posthogProxyDomain, 'Report viewed', {
                        environment: info?.environment,
                        orgCode: orgData?.organisationCode,
                        orgName: orgData?.organisationName,
                        userType: reportDetails.demographics?.userType,
                        referralCode: reportDetails.demographics?.referralCode,
                        reportId: id,
                        clinicianUserId,
                    })

                    posthogEventSent.current = true
                } catch (error) {
                    Sentry.captureException(error)
                }
            }
        })()
    }, [reportDetails])

    if (dbReportLoading || genReportLoading) return <Splash />

    if (!isDemoAssessment && ((dbReportError && !genReportData) || (genReportError && !dbReportData))) {
        const errorToReport = dbReportError || genReportError
        Sentry.captureException(errorToReport, {
            extra: { detail: `Report id: ${id}, User: ${currentUserId}` },
            tags: { section: 'Load report', reportVersion: 2 },
        })
        return <CenseoError homepageButton={false} customTitle={`Failed to load report (Report ID: ${id})`} />
    }

    if (
        dbReportData?.fetchJsonReport.message === ReportResultMessage.NotFound &&
        genReportData?.reportDetails.message === ReportResultMessage.NotFound
    ) {
        Sentry.captureException(new Error('Report not found'), {
            extra: { detail: `Report id: ${id} User: ${currentUserId}` },
            tags: { section: 'Load report', reportVersion: 2 },
        })
        return <NotFound homepageButton={true} />
    }

    if (
        dbReportData?.fetchJsonReport.message === ReportResultMessage.AccessDenied ||
        genReportData?.reportDetails.message === ReportResultMessage.AccessDenied
    ) {
        return (
            <CenseoError
                homepageButton={false}
                customTitle="Report Not Accessible"
                customMessage="Something went wrong, please contact your system administrator."
            />
        )
    }

    if (reportDetails) {
        return (
            <Page {...pageProps}>
                {pdfErrorToShow && (
                    <Modal onClose={closePdfError} header="PDF fetching Error">
                        Failed to download pdf (Report ID: ${id}). <br></br> If this continues please contact support
                        at: <a href="mailto:support@censeo.co.uk">support@censeo.co.uk</a>
                    </Modal>
                )}
                {(foundPdf || isDemoAssessment) && (
                    <div style={{ position: 'absolute', right: 'var(--s-3)' }}>
                        <Button
                            id="pdf-button"
                            style={{
                                cursor: 'pointer',
                                position: 'relative',
                                top: '-16px',
                            }}
                            appearance="primary"
                            icon="pdf"
                            rounded={true}
                            size="large"
                            onClick={() => {
                                if (isDemoAssessment) {
                                    if (id) {
                                        downloadFromLink(demoReports.pdfLocation(id))
                                    }
                                } else {
                                    handlePdfDownloadClick()
                                }
                            }}
                        ></Button>
                    </div>
                )}
                {!patientFacing && (
                    <ReportNav
                        reportMaxWidth="1296"
                        allCompletedReportsForUser={allCompletedReportsForUser}
                        currentAssessmentId={id}
                        isAuthorisedForPatient={isAuthorisedForPatient}
                        setIsAuthorisedForPatient={setIsAuthorisedForPatient}
                    />
                )}
                <ReportTabs
                    report={reportDetails}
                    activeTab={activeTab}
                    onTabChange={handleTabClick}
                    navigateToConditions={handleNavigateToConditions}
                    conditionCode={clickedConditionCode}
                    pageScrollElementRef={scrollingElementRef}
                    locale={demoLocale}
                    patientFacing={patientFacing || reportDetails.demographics?.userType === UserType.D2C}
                />
            </Page>
        )
    }

    return <Splash />
}
