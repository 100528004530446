import React from 'react'
import CrisisContent from 'ui/app/components/CrisisContent'
import ContentPage from 'ui/app/components/ContentPage'
import { useNavigate } from 'react-router'

export const CrisisInformation: React.FC = () => {
    const navigate = useNavigate()
    const onBack = () => {
        navigate(-1)
    }
    return (
        <ContentPage showCrisisLink={false} headingText="Urgent help" headingButton={{ text: 'Back', link: onBack }}>
            <CrisisContent />
        </ContentPage>
    )
}
